/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Link,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
  Container,
  Autocomplete,
  CircularProgress,
  TablePagination,
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Toolbar,
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  GetAllClient,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  listOfManufactureNDistributorCompanies,
} from "../api/Api";
import States from "../Constants/States";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ImportClient from "./ImportClient";
import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { isValidUsername } from "../utils/Helper";

const Clients = () => {
  const [open, setOpen] = useState(false);
  const [Clients, setClients] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [nomineePancard, setNomineePancard] = useState<any>();
  const [cancelCheque, setCancelCheque] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>();
  const [aadhaarCard, setAadhaarCard] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [CMLdocument, setCMLdocument] = useState<any>();
  const [value, setValueDate] = React.useState<Dayjs | null | any>(
    dayjs("2022-04-07")
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [userType, setUserType] = useState("");
  // state for RM NAME
  const [rmName, setRmName] = React.useState("");
  // state for RM EMPLOYEE CODE
  const [rmEmployeeCode, setRmEmployeeCode] = React.useState("");

  const { data: ListOfCompany } = useQuery(
    "ListOfManuAndDistList",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled: sessionStorage.getItem("Profile") === "B",
      refetchOnWindowFocus: false,
    }
  );

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => setOpen(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    // If the search term is empty, reset the page to 0, otherwise, keep the current page
    if (event.target.value === "") {
      setPage(0);
    }
  };
  const handleSort = () => {
    // Toggle between "asc" and "desc" when clicking on the sorting button
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const filteredClients = Clients.filter((item: any) => {
    return (
      item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.panCardNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.clientCode.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const sortedAndFilteredClients = [...filteredClients].sort(
    (a: any, b: any) => {
      const nameA = a.fullName.toLowerCase();
      const nameB = b.fullName.toLowerCase();

      if (sortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    }
  );

  const registerOptions: any = {
    // companyCode : { required: "Select Company Name is required" },
    fullName: {
      required: "Full Name required",
      validate: (value: string) => {
        // const alphanumericRegex =
        //   /^[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
        // if (!alphanumericRegex.test(value)) {
        //   return "Only alphanumeric are allowed here!";
        // }
        // return true;
        if (!isValidUsername(value)) {
          return "Only letters, numbers, special characters(not more than 3) are allowed!";
        }
        return true;
      },
    },
    companyName: { required: "select distributor Company" },
    // mobileNo : { required: "Mobile No is required" },
    emailId: { required: "Email Id is required" },
    // aadhaarCardNo: { required: "Aadhar Card No is required" },
    pancard: { required: "Pan Card No is required" },
    addressL1: { required: "Address Line 1 is required" },
    //addressL2: { required: "Address Line 2 is required" },
    //addressL3: { required: "Address Line 3 is required" },
    // city: { required: "City is required" },
    // pincode: { required: "Pin Code is required" },
    // country: { required: "Country is required" },
    // state:{ required: "State is required" },
    // clientcode:{ required: "Client Code is required" },
    // userrole : { required: "Full Name is required" },
    // companyName : { required: "Company Name is required" },
    bankName: { required: "Bank Name is required" },
    branchName: { required: "Branch Name is required" },
    accountNo: {
      required: "Account No is required",
      validate: (value: string) => {
        const accountNoRegex = /^[0-9]{8,30}$/;
        if (!accountNoRegex.test(value)) {
          return "Account No. should be between 8 to 30 digits (only numbers)";
        }
        return true;
      },
    },
    ifscCode: {
      required: "IFSC Code is required",
      validate: (value: string) => {
        const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!ifscCodeRegex.test(value)) {
          return "IFSC Code should be 11 characters long, first four characters should be uppercase alphabets, fifth character should be 0, last 6 characters should be numbers but can also have alphabets";
        }
        return true;
      },
    },
    // dpName : { required: "Dp Name is required" },
    // dpId : { required: "Dp Id is required" },
    // rmName : { required: "Rm Name is required" },
    // rmBranch : { required: "Rm Branch is required" },
    // rmBranchCode : { required: "Rm Branch Code is required" },
    // rmEmpCode : { required: "Rm Employee Code is required" },
    // distributorEmployeeCode : { required: "Distributor Employee Code is required" },
    // availabilityDate : { required: "availabilityDate is required" },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm();

  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    const formData: any = new FormData();
    formData.append("panCardDocument", nomineePancard);
    formData.append("cancelCheque", cancelCheque);
    formData.append("profileImage", profileImage);
    formData.append("aadhaarCardDocument", aadhaarCard);
    formData.append("clientMasterList", CMLdocument);
    formData.append("clientDto", JSON.stringify(data));
    axios
      .post("client/saveClient", formData)
      .then(() => toast.success("Client Added Successfully"))
      .then(() => {
        setshowCircularProgress(false);
        setOpen(false);
        window.location.reload();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        );
      });
  };

  const { data } = useQuery("getAllClients", () => GetAllClient(), {
    onSuccess: (data: any) => {
      const newData = data.data.clients.map((item: any, index: any) => {
        return { ...item, SrNo: index + 1 };
      });
      setClients(newData);
    },
    refetchOnWindowFocus: false,
  });

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", watch("companyMasterCode"), userType],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        userType === "S" ? "E" : userType,
        getValues("companyMasterCode")
      ),
    { enabled: !!getValues("companyMasterCode"), refetchOnWindowFocus: false }
  );

  function EnhancedTableToolbar(props: any) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleButtonClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    function downloadPayoutExcel() {
      axios({
        url: `/client/clientListExcelDownload?forSelf=${false}`,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        var date1 = new Date();
        link.setAttribute(
          "download",
          "Clients List_" +
            ("0" + date1.getDate()).slice(-2) +
            "_" +
            ("0" + (date1.getMonth() + 1)).slice(-2) +
            "_" +
            date1.getFullYear() +
            ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      });
    }
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Button variant="contained" onClick={handleButtonClick} sx={{ m: 2 }}>
          Download
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={downloadPayoutExcel}>
            <Button
              variant="text"
              color="primary"
              startIcon={<DownloadForOfflineIcon />}
            >
              Excel
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ paddingTop: 10, "& .MuiTextField-root": { m: 1, width: "25ch" } }}
      >
        <Grid container gap={2}>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Add Client
          </Button>
          <Button variant="contained" onClick={() => setOpenImportModal(true)}>
            Import Client
          </Button>

          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Client by Name or Pan Card or Client Code"
                onChange={handleSearch}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              ></IconButton>
            </Paper>

            <EnhancedTableToolbar />
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: "2%" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr No.</TableCell>
                <TableCell onClick={handleSort} style={{ cursor: "pointer" }}>
                  Client Name {sortOrder === "asc" ? "▲" : "▼"}
                </TableCell>
                <TableCell>Client PAN</TableCell>
                <TableCell>Client Email Id</TableCell>
                <TableCell>D1 Name</TableCell>
                <TableCell>L1 Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAndFilteredClients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, index: any) => (
                  <Link
                    key={item.clientId}
                    display={"table-row"}
                    sx={{
                      textDecoration: "none",
                      "&:hover": { backgroundColor: "lightgray" },
                    }}
                    href={"/client/" + item.clientId}
                  >
                    <TableCell align="left">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.fullName}
                    </TableCell>
                    <TableCell>{item.panCardNo}</TableCell>
                    <TableCell component="th" scope="row">
                      {item.emailId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.companyName ? item.companyName : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.contactPersonName !== null
                        ? item.contactPersonName
                        : "-"}
                    </TableCell>
                  </Link>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          // count={data ? data.data.clients.length : 0}
          count={sortedAndFilteredClients ? sortedAndFilteredClients.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modal-scroll">
            <IconButton
              style={{
                position: "absolute",
                fontSize: "18px",
                top: "8px",
                right: "5px",
              }}
              onClick={() => handleClose()}
            >
              <CloseIcon style={{ fontSize: "18px" }} />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "500" }}
              >
                Client Details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="PUBLIC"
                >
                  <Stack direction={"row"} spacing={3}>
                    <FormControlLabel
                      value="PRIVATE"
                      {...register("accessType")}
                      control={<Radio />}
                      label="Private"
                    />
                    <FormControlLabel
                      value="PUBLIC"
                      {...register("accessType")}
                      control={<Radio />}
                      label="Public"
                    />
                  </Stack>
                </RadioGroup> */}

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="INDIVIDUAL"
                >
                  <Stack direction={"row"} spacing={3}>
                    <FormControlLabel
                      value="INDIVIDUAL"
                      {...register("clientType")}
                      control={<Radio />}
                      label="Individual"
                    />
                    <FormControlLabel
                      value="CORPORATE"
                      {...register("clientType")}
                      control={<Radio />}
                      label="Corporate"
                    />
                  </Stack>
                </RadioGroup>
              </Box>

              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                {sessionStorage.getItem("Profile") === "B" ? (
                  <Grid item md={3}>
                    <Autocomplete
                      disableClearable
                      options={
                        ListOfCompany ? ListOfCompany.data.companies : []
                      }
                      loading={
                        ListOfCompany ? !ListOfCompany.data.companies : true
                      }
                      getOptionLabel={(option: any) =>
                        option.companyName + " - " + option.companyTypeCode
                      }
                      onChange={(event: any, value: any) => {
                        setValue(
                          "companyMasterCode",
                          !!value ? value.companyMasterCode : ""
                        );
                        setUserType(value.companyTypeCode);
                        // Set RM Name and RM User Code values
                        if (value) {
                          setValue("rmName", value.rmName || "");
                          setRmName(value.rmName || "");
                          setValue("rmEmpCode", value.rmUserCode || "");
                          setRmEmployeeCode(value.rmUserCode || "");
                        } else {
                          setValue("rmName", "");
                          setRmName("");
                          setValue("rmEmpCode", "");
                          setRmEmployeeCode("");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Distributor Company *"
                          variant="outlined"
                          error={errors.companyName ? true : false}
                          helperText={
                            errors.companyName == undefined
                              ? ""
                              : errors.companyName.message + ""
                          }
                          {...register(
                            "companyName",
                            registerOptions.companyName
                          )}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Full Name *"
                    variant="outlined"
                    fullWidth
                    error={errors.fullName ? true : false}
                    helperText={
                      errors.fullName === undefined
                        ? ""
                        : errors.fullName.message + ""
                    }
                    {...register("fullName", registerOptions.fullName)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Mobile No"
                    variant="outlined"
                    fullWidth
                    error={errors.mobileNo ? true : false}
                    helperText={
                      errors.mobileNo === undefined
                        ? ""
                        : errors.mobileNo.message + ""
                    }
                    {...register("mobileNo", registerOptions.mobileNo)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Email Id *"
                    variant="outlined"
                    type="email"
                    fullWidth
                    error={errors.emailId ? true : false}
                    helperText={
                      errors.emailId === undefined
                        ? ""
                        : errors.emailId.message + ""
                    }
                    {...register("emailId", registerOptions.emailId)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="PAN Card No *"
                    variant="outlined"
                    fullWidth
                    error={errors.panCardNo ? true : false}
                    helperText={
                      errors.panCardNo === undefined
                        ? ""
                        : errors.panCardNo.message + ""
                    }
                    {...register("panCardNo", registerOptions.pancard)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Aadhaar Card No"
                    variant="outlined"
                    fullWidth
                    error={errors.aadhaarCardNo ? true : false}
                    helperText={
                      errors.aadhaarCardNo === undefined
                        ? ""
                        : errors.aadhaarCardNo.message + ""
                    }
                    {...register(
                      "aadhaarCardNo",
                      registerOptions.aadhaarCardNo
                    )}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Address Line 1 *"
                    variant="outlined"
                    fullWidth
                    error={errors.addressLine1 ? true : false}
                    helperText={
                      errors.addressLine1 === undefined
                        ? ""
                        : errors.addressLine1.message + ""
                    }
                    {...register("addressLine1", registerOptions.addressL1)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Address Line 2"
                    variant="outlined"
                    fullWidth
                    error={errors.addressLine2 ? true : false}
                    helperText={
                      errors.addressLine2 === undefined
                        ? ""
                        : errors.addressLine2.message + ""
                    }
                    {...register("addressLine2", registerOptions.addressL2)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Address Line 3"
                    variant="outlined"
                    fullWidth
                    error={errors.addressLine3 ? true : false}
                    helperText={
                      errors.addressLine3 === undefined
                        ? ""
                        : errors.addressLine3.message + ""
                    }
                    {...register("addressLine3", registerOptions.addressL3)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    fullWidth
                    error={errors.city ? true : false}
                    helperText={
                      errors.city === undefined ? "" : errors.city.message + ""
                    }
                    {...register("city", registerOptions.city)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Pin Code"
                    variant="outlined"
                    fullWidth
                    error={errors.pinCode ? true : false}
                    helperText={
                      errors.pinCode === undefined
                        ? ""
                        : errors.pinCode.message + ""
                    }
                    {...register("pinCode", registerOptions.pincode)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Client Code"
                    variant="outlined"
                    fullWidth
                    error={errors.clientCode ? true : false}
                    helperText={
                      errors.clientCode === undefined
                        ? ""
                        : errors.clientCode.message + ""
                    }
                    {...register("clientCode", registerOptions.clientcode)}
                  />
                </Grid>
                <Grid item md={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={[
                      {
                        label: "RESIDENT INDIVIDUAL",
                        value: "RESIDENT_INDIVIDUAL",
                      },
                      {
                        label: "NON RESIDENT INDIVIDUAL",
                        value: "NON_RESIDENT_INDIVIDUAL",
                      },
                    ].map((option: any) => option)}
                    getOptionLabel={(options: any) => options.label}
                    onChange={(options: any, newValue) => {
                      setValue("residentialStatus", newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="User Role" />
                    )}
                  />
                </Grid>

                <Grid item md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label={
                        watch("clientType") === "CORPORATE"
                          ? "Date of Incorporation"
                          : "Date of Birth"
                      }
                      inputFormat="DD/MM/YYYY"
                      value={value}
                      onChange={(newValue) => {
                        setValueDate(newValue.toJSON());
                        setValue("dateOfBirth", newValue.toJSON());
                      }}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item md={3}>
                  <Autocomplete
                    options={States.countries}
                    getOptionLabel={(option: any) => option.country}
                    onChange={(event: any, value: any) =>
                      setSelectedCountry(value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        variant="outlined"
                        error={errors.country ? true : false}
                        helperText={
                          errors.country === undefined
                            ? ""
                            : errors.country.message + ""
                        }
                        {...register("country", registerOptions.country)}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={3}>
                  {selectedCountry && (
                    <Autocomplete
                      options={selectedCountry.states}
                      onChange={(event: any, value: any) =>
                        setSelectedState(value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          error={errors.state ? true : false}
                          helperText={
                            errors.state === undefined
                              ? ""
                              : errors.state.message + ""
                          }
                          {...register("state", registerOptions.state)}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>

              <Typography
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "500" }}
              >
                Bank Details
              </Typography>

              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Bank Name *"
                    variant="outlined"
                    fullWidth
                    error={errors.bankName ? true : false}
                    helperText={
                      errors.bankName === undefined
                        ? ""
                        : errors.bankName.message + ""
                    }
                    {...register("bankName", registerOptions.bankName)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Branch Name *"
                    variant="outlined"
                    fullWidth
                    error={errors.branchName ? true : false}
                    helperText={
                      errors.branchName === undefined
                        ? ""
                        : errors.branchName.message + ""
                    }
                    {...register("branchName", registerOptions.branchName)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="Account No *"
                    variant="outlined"
                    fullWidth
                    error={errors.accountNo ? true : false}
                    helperText={
                      errors.accountNo === undefined
                        ? ""
                        : errors.accountNo.message + ""
                    }
                    {...register("accountNo", registerOptions.accountNo)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="IFSC Code *"
                    variant="outlined"
                    fullWidth
                    error={errors.ifscCode ? true : false}
                    helperText={
                      errors.ifscCode === undefined
                        ? ""
                        : errors.ifscCode.message + ""
                    }
                    {...register("ifscCode", registerOptions.ifscCode)}
                  />
                </Grid>

                <Grid item md={3}></Grid>
                <Grid item md={3}></Grid>
              </Grid>

              <Typography
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "500" }}
              >
                DP Details
              </Typography>

              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="DP Name"
                    variant="outlined"
                    fullWidth
                    error={errors.dpName ? true : false}
                    helperText={
                      errors.dpName === undefined
                        ? ""
                        : errors.dpName.message + ""
                    }
                    {...register("dpName", registerOptions.dpName)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="DP ID"
                    variant="outlined"
                    fullWidth
                    error={errors.dpId ? true : false}
                    helperText={
                      errors.dpId === undefined ? "" : errors.dpId.message + ""
                    }
                    {...register("dpId", registerOptions.dpId)}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Name"
                    variant="outlined"
                    fullWidth
                    error={errors.rmName ? true : false}
                    helperText={
                      errors.rmName === undefined
                        ? ""
                        : errors.rmName.message + ""
                    }
                    {...register("rmName", registerOptions.rmName)}
                    disabled
                    value={rmName}
                  />
                </Grid>

                {/* <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Branch"
                    variant="outlined"
                    fullWidth
                    error={errors.rmBranch ? true : false}
                    helperText={
                      errors.rmBranch === undefined
                        ? ""
                        : errors.rmBranch.message + ""
                    }
                    {...register("rmBranch", registerOptions.rmBranch)}
                  />
                </Grid> */}

                {/* <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Branch Code"
                    variant="outlined"
                    fullWidth
                    error={errors.rmBranch ? true : false}
                    helperText={
                      errors.rmBranch === undefined
                        ? ""
                        : errors.rmBranch.message + ""
                    }
                    {...register("rmBranchCode", registerOptions.rmBranchCode)}
                  />
                </Grid> */}

                <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Employee Code"
                    variant="outlined"
                    fullWidth
                    error={errors.rmEmpCode ? true : false}
                    helperText={
                      errors.rmEmpCode === undefined
                        ? ""
                        : errors.rmEmpCode.message + ""
                    }
                    {...register("rmEmpCode", registerOptions.rmEmpCode)}
                    disabled
                    value={rmEmployeeCode}
                  />
                </Grid>

                {sessionStorage.getItem("Profile") === "B" ? (
                  <Grid item md={3}>
                    <Autocomplete
                      options={
                        UserProfilesByCompanyMasterCode
                          ? UserProfilesByCompanyMasterCode.data.userProfile
                          : []
                      }
                      getOptionLabel={(option: any) => option.employeeCode}
                      onChange={(event: any, value: any) => {
                        setValue(
                          "distributorEmployeeCode",
                          !!value ? value.employeeCode : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Distributor"
                          variant="outlined"
                          error={errors.distributorEmployeeCode ? true : false}
                          helperText={
                            !!errors.distributorEmployeeCode &&
                            errors.distributorEmployeeCode.message + ""
                          }
                          {...register(
                            "distributorEmployeeCode",
                            registerOptions.distributorEmployeeCode
                          )}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              <Typography
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "500" }}
              >
                Document Upload
              </Typography>

              <Stack spacing={3} direction="column" mb={3}>
                <Box>
                  <Button variant="contained" component="label">
                    Upload PAN Card
                    <input
                      hidden
                      type="file"
                      accept=".pdf"
                      name="panCardDocument"
                      onChange={(event: any) =>
                        setNomineePancard(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {nomineePancard == undefined
                      ? "No file choosen"
                      : nomineePancard.name}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="contained" component="label">
                    Upload Client Master List
                    <input
                      hidden
                      type="file"
                      accept=".pdf"
                      name="cmlDocument"
                      onChange={(event: any) =>
                        setCMLdocument(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {CMLdocument == undefined
                      ? "No file choosen"
                      : CMLdocument.name}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="contained" component="label">
                    Upload Cancel Cheque
                    <input
                      hidden
                      type="file"
                      accept=".pdf"
                      name="cancelCheque"
                      onChange={(event: any) =>
                        setCancelCheque(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {cancelCheque == undefined
                      ? "No file choosen"
                      : cancelCheque.name}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="contained" component="label">
                    Upload Profile Image
                    <input
                      hidden
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      name="profileImage"
                      onChange={(event: any) =>
                        setProfileImage(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {profileImage == undefined
                      ? "No file choosen"
                      : profileImage.name}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="contained" component="label">
                    Upload Aadhaar Card
                    <input
                      hidden
                      type="file"
                      accept=".jpg,.jpeg,.png,.pdf"
                      name="aadhaarCard"
                      onChange={(event: any) =>
                        setAadhaarCard(event.target.files[0])
                      }
                    />
                  </Button>
                  <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                    {aadhaarCard == undefined
                      ? "No file choosen"
                      : aadhaarCard.name}
                  </Typography>
                </Box>
              </Stack>
              <Button
                type="submit"
                variant="contained"
                disabled={showCircularProgress}
              >
                {!!showCircularProgress && (
                  <CircularProgress
                    color="secondary"
                    size={20}
                    thickness={5}
                    sx={{ mr: 1 }}
                  />
                )}
                Submit
              </Button>
            </form>
          </Box>
        </Modal>
      </Box>
      <ImportClient
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
      />
    </Container>
  );
};

export default Clients;

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};
