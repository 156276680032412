/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { GetUnlistedDetails } from "../api/Api";
import { useQuery } from "react-query";
import { changeDate, convertDate } from "../utils/Helper";
import { Link } from "react-router-dom";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { bondEnum } from "../Constants/bondEnum";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: any) {
  const stabilizedThis = array?.map((el: any, index: any) => [el, index]);
  stabilizedThis?.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el: any) => el[0]);
}

const headCells = [
  {
    id: "orderId",
    numeric: false,
    disablePadding: true,
    label: "Order ID",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Order Date",
  },
  {
    id: "productName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "productType",
    numeric: false,
    disablePadding: false,
    label: "Product Type",
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
  },
  {
    id: "investmentAmount",
    numeric: false,
    disablePadding: false,
    label: "Investment Amount",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "totalPrice",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props: any) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableToolbar(props: any) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {
        numSelected > 0 && (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} Selected
          </Typography>
        )
        // : (
        //   <Typography
        //     sx={{ flex: "1 1 100%" }}
        //     variant="h6"
        //     id="tableTitle"
        //     component="div"
        //   >
        //     Order Book
        //   </Typography>
        // )
      }

      {/* {numSelected > 0 ? (
        <Tooltip title="Download">
          <IconButton>
            <DownloadForOfflineIcon onClick={() => downloadSingleInvoice(selectedPayout)} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Download">
          <IconButton>
            <DownloadForOfflineIcon onClick={downloadPayoutPDF} />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const OrderBookTableData = (props: any) => {
  // const [selectedTab, setSelectedTab] = useState('PENDING');

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("orderId");
  const [selected, setSelected] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState<any>([]);
  const [refresh, setRefresh] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [fromDate, setFromDate] = React.useState(
    new Date(new Date().setMonth(new Date().getMonth() - 3)).toJSON()
  );
  const [toDate, setToDatee] = React.useState(new Date().toJSON());

  const [investmentAmount, setInvestmentAmount] = React.useState<any>("");
  const [ProductNameData, setproductName] = React.useState<string[]>([]);
  const [checkedValue, setCheckedValue] = useState("");
  const [showCircularProgress, setshowCircularProgress] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const { selectedTab } = props;

  const productNamesForAll = [
    { id: 1, label: "Unlisted", value: "11" },
    { id: 2, label: "Bond", value: "12" },
    { id: 3, label: "AIF", value: "5" },
    { id: 4, label: "PMS", value: "4" },
    { id: 5, label: "Research Services", value: "13" },
    { id: 6, label: "NCD", value: "14" },
  ];

  const productNamesForDistributors = [
    { id: 1, label: "Unlisted", value: "11" },
    { id: 3, label: "AIF", value: "5" },
    { id: 4, label: "PMS", value: "4" },
    { id: 5, label: "Research Services", value: "13" },
    { id: 6, label: "NCD", value: "14" },
  ];

  // const { data } = useQuery([`GetUnlistedDetail_${selectedTab}`, fromDate, toDate,investmentAmount,ProductNameData],
  // () => GetUnlistedDetails("true", selectedTab, fromDate, toDate, ProductNameData,investmentAmount), {
  //   onSuccess(data) {
  //     setRows(data.data.orderList);
  //   },
  // });
  const { data } = useQuery(
    [
      `GetUnlistedDetail_${selectedTab}`,
      fromDate,
      toDate,
      investmentAmount,
      ProductNameData,
    ],
    () =>
      GetUnlistedDetails(
        "true",
        selectedTab,
        fromDate,
        toDate,
        ProductNameData,
        investmentAmount
      ),
    {
      onSuccess(data) {
        setRows(data.data.orderList);
        // setshowCircularProgress(false)
      },
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (data) {
      setRows(filteredRows);
      setshowCircularProgress(false);
    }
  }, [data, showCircularProgress]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected: any = rows.map((n: any) => n.orderId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, name: any) => {
    const selectedIndex: any = selected.indexOf(name);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeDense = (event: any) => {
    setDense(event.target.checked);
  };

  const isSelected: any = (name: never) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, visibleRows.length - page * rowsPerPage);

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = React.useMemo(() => {
    return (
      data &&
      data.data.orderList.filter((item: any) => {
        return (
          item.clientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productType?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [data, searchTerm]);

  function setToDate(arg0: any) {
    throw new Error("Function not implemented.");
  }

  useEffect(() => {
    data && setRows(filteredRows);
  }, [filteredRows]);

  //start filter

  const handleInvestmentAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInvestmentAmount((event.target as HTMLInputElement).value);
  };

  const handleProductNameChange = (event: any, prodID: any) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    // setproductName((prevProductNames) => {
    //   if (isChecked) {
    //     return [value];
    //   } else {
    //     return [];
    //   }
    // });

    if (selectedProducts.includes(prodID)) {
      setSelectedProducts(
        selectedProducts.filter((name: any) => name !== prodID)
      );
    } else {
      setSelectedProducts([...selectedProducts, prodID]);
    }

    if (isChecked) {
      setproductName([...ProductNameData, value]);
    } else {
      setproductName(ProductNameData.filter((item) => item !== value));
    }

    // setCheckedValue(isChecked ? value : "");
    setPage(0);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className="filter_container filter_container-filter-aif-main">
            <div className="filter_dropdowns">
              <Paper
                sx={{
                  height: "47px",
                  width: "100%",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    letterSpacing: "1px",
                    fontWeight: "600",
                    padding: "10px",
                    color: "primary",
                    marginLeft: "5px",
                  }}
                >
                  Filters
                </Typography>
                <TuneRoundedIcon
                  sx={{ fontSize: "1rem", marginTop: "15px", color: "primary" }}
                />
              </Paper>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {/* <SendRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
                  <Typography
                    sx={{
                      color: "#3c3b3b",
                      //   marginLeft: "8px",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      letterSpacing: "1px",
                    }}
                  >
                    Product Name
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <FormGroup>
                      {sessionStorage.getItem("Profile") !== "D" &&
                        productNamesForAll.map((prodName) => {
                          const { id, label, value } = prodName;
                          return (
                            <FormControlLabel
                              key={id}
                              control={<Checkbox />}
                              label={label}
                              value={value}
                              onChange={(e) =>
                                handleProductNameChange(e, value)
                              }
                              // checked={checkedValue === value}
                            />
                          );
                        })}
                      {sessionStorage.getItem("Profile") === "D" &&
                        productNamesForDistributors.map((prodName) => {
                          const { id, label, value } = prodName;
                          return (
                            <FormControlLabel
                              key={id}
                              control={<Checkbox />}
                              label={label}
                              value={value}
                              onChange={(e) =>
                                handleProductNameChange(e, value)
                              }
                              // checked={checkedValue === value}
                            />
                          );
                        })}
                    </FormGroup>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{
                      color: "#3c3b3b",
                      //   marginLeft: "8px",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      letterSpacing: "1px",
                    }}
                  >
                    Investment Amount
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <RadioGroup
                      value={investmentAmount}
                      onChange={handleInvestmentAmountChange}
                    >
                      <Button
                        size="small"
                        onClick={() => setInvestmentAmount("")}
                      >
                        Clear
                      </Button>
                      <FormControlLabel
                        value="&maxInvAmt=1000000"
                        control={<Radio />}
                        label="< 10 Lacs"
                      />
                      <FormControlLabel
                        value="&maxInvAmt=5000000&minInvAmt=1000000"
                        control={<Radio />}
                        label="10 - 50 Lacs"
                      />
                      <FormControlLabel
                        value="&minInvAmt=5000000"
                        control={<Radio />}
                        label=" > 50 Lacs"
                      />
                    </RadioGroup>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </Grid>

        <Grid item xs={9}>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Grid>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 450,
                  mb: 2,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, width: "400px" }}
                  placeholder="🔍 Search By Client Name, Product Name, Product Type"
                  onChange={handleSearch}
                  // inputRef={(input) => input && input.focus()}
                  // autoFocus={true}
                />
                <IconButton type="button" aria-label="search"></IconButton>
              </Paper>
            </Grid>
            <Grid>
              <Stack direction={"row"} spacing={3} width={"80%"} marginLeft={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="From Date"
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    onChange={(newValue: any) => {
                      setFromDate(newValue.toJSON());
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        defaultValue={fromDate}
                        sx={{ width: "50%", marginBottom: "20px" }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="To Date"
                    inputFormat="DD/MM/YYYY"
                    value={toDate}
                    onChange={(newValue: any) => {
                      setToDatee(newValue.toJSON());
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        defaultValue={toDate}
                        sx={{ width: "50%", marginBottom: "20px" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
          </Grid>
          {showCircularProgress === true ? (
            <CircularProgress color="secondary" size={50} thickness={5} />
          ) : (
            <Box sx={{ width: "100%" }}>
              <Grid className="orderbook-EnhancedTableToolbar">
                <EnhancedTableToolbar
                  numSelected={selected.length}
                  // selectedPayout={selected}
                />
              </Grid>

              <Paper sx={{ width: "100%", mb: 2, minHeight: "10%" }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {visibleRows.map((row: any, index: any) => {
                        const isItemSelected = isSelected(row.orderId);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            //onClick={(event) => handleClick(event, row.orderId)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.orderId}
                            selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                                color="primary"
                                onClick={(event: any) =>
                                  handleClick(event, row.orderId)
                                }
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              /> */}
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.orderId}
                            </TableCell>
                            <TableCell align="left">{row.orderDate}</TableCell>
                            <TableCell align="left">
                              {row.productName}
                            </TableCell>
                            <TableCell align="left">
                              {row.productType}
                            </TableCell>
                            <TableCell align="left">
                              {row.clientName === "null" ? "-" : row.clientName}
                              {row.investmentAmount === "null"
                                ? "-"
                                : row.investmentAmount}
                            </TableCell>
                            <TableCell align="left">
                              {row.totalTransactionValue === "null"
                                ? "-"
                                : row.totalTransactionValue}
                            </TableCell>
                            <TableCell align="left">
                              {row.orderStatus === "null"
                                ? "-"
                                : row.currentStepName}
                            </TableCell>
                            <TableCell align="left">
                              <Link
                                to={
                                  "/orderbook/" +
                                  row.orderId +
                                  "/" +
                                  row.productTypeCode
                                }
                              >
                                <Button variant="contained">View</Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            minHeight: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default OrderBookTableData;
