import React from 'react'
import { Box, Button, Card, CardActions, CardContent, CardMedia, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import { numDifferentiation } from '../../utils/Helper'
import { Link } from 'react-router-dom';

const RevenueForEmployee = (props:any) => {
  const { title } = props
  const { revenueYearlyCount } = props
  const { revenueQuarterlyCount } = props
  const { revenueMonthlyCount } = props 
  const { prevQuarterValueRevenue } = props 
  const {prevQuarterStartDate} = props
  const {prevQuarterEndDate}  =props
  const {yearly}=props
  const {quarterly}=props
  const {monthly}=props
  const {currentDate}=props

  return (
	<Paper elevation={6} sx={{pb:0}}>
		 <Card sx={{pb:0}}>
      <Table aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell sx={{background:"#ffc000"}} colSpan={4} align="center">
          {title}(Rs)
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{fontSize:"12px",fontWeight:"700",textAlign:"center"}}>Month <br /> <b style={{fontSize:"10px"}}>({monthly})</b> </TableCell>
        <TableCell style={{fontSize:"12px",fontWeight:"700",padding:"0"}} align="center">QTR <br /> <b style={{fontSize:"10px"}}>({quarterly})</b></TableCell>
        <TableCell style={{fontSize:"12px",fontWeight:"700",padding:"0"}} align="center">Prv QTR <br /> <b style={{fontSize:"10px"}}>({prevQuarterStartDate}-{prevQuarterEndDate})</b> </TableCell>
        <TableCell style={{fontSize:"12px",fontWeight:"700"}} align="center">YTD <br /> <b style={{fontSize:"10px"}}>({currentDate})</b> </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell style={{fontSize:"10px",textAlign:"center"}} component="th" scope="row"><b>{numDifferentiation(revenueMonthlyCount)}</b></TableCell>
        <TableCell style={{fontSize:"10px"}} align="center"><b>{numDifferentiation(revenueQuarterlyCount)}</b></TableCell>
        <TableCell style={{fontSize:"10px"}} align="center"><b>{numDifferentiation(prevQuarterValueRevenue)}</b></TableCell>
        <TableCell style={{fontSize:"10px"}} align="center"> <b>{numDifferentiation(revenueYearlyCount)}</b></TableCell>
      </TableRow>
    </TableBody>
  </Table>
    </Card>
	</Paper>
  )
}

export default RevenueForEmployee