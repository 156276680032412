import { Grid, Container } from "@mui/material";
import React from "react";
import logo from "../images/Neofindesk-Logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";
import "../css/footer.css";

const Footer = () => {
  return (
    <div className="p-5 color-white bg-dark footer footermain">
      <Container className="container">
        <Grid container className="footer-grid-cont">
          <Grid item md={4} className="mb-footer-main">
            <img src={logo} className="footer_logo" />
            <p className="ft">
              303,A Wing,Gokul Arcade,
              <br />
              Near Garware House,
              <br />
              Sahar Road,Ville Parle (E),
              <br />
              Mumbai - 400057
              <br />
              <br />
            </p>
            <p className="ft">Email : info@investvalue.in</p>
            {/* <p className="ft">Phone : +91 98995 75852</p> */}
            <br />
            <div className="Social-icons">
              <div className="social-icon">
                <a href="#" className="icons">
                  <InstagramIcon />
                </a>
              </div>
              <div className="social-icon">
                <a href="#" className="icons">
                  <FacebookIcon />
                </a>
              </div>
              <div className="social-icon">
                <a href="#" className="icons">
                  <TwitterIcon />
                </a>
              </div>
            </div>
          </Grid>
          <Grid sx={{textAlign:'left', paddingLeft:'10rem'}} item md={4} className="col-4 mb-footer-main">
            <h6 className="head">Products</h6>
            <Grid className="product-list">
              <Link to="" className="footer-anchor">
                Fixed Deposit
              </Link>
              <Link to="" className="footer-anchor">
                Mutual Fund
              </Link>
              <Link to="" className="footer-anchor">
                Portfolio Management Services
              </Link>
              <Link to="" className="footer-anchor">
                Alternative Investment Fund
              </Link>
              <Link to="" className="footer-anchor">
                Primary Issue
              </Link>
              <Link to="" className="footer-anchor">
                Insurance
              </Link>
              <Link to="" className="footer-anchor">
                Real Estate
              </Link>
              <Link to="" className="footer-anchor">
                Startup Ecosystem
              </Link>
              <Link to="" className="footer-anchor">
                Equity Reserch
              </Link>
              <Link to="" className="footer-anchor">
                Training
              </Link>
            </Grid>
          </Grid>
          <Grid sx={{textAlign:'left', paddingLeft:'10rem'}} item md={4} className="col-4 mb-footer-main">
            <h6 className="head">Quick Links</h6>
            <Grid className="quickLink-list">
              <Link to="/Team" className="footer-anchor">
                Team
              </Link>
              {/* <Link to="/#product" className="footer-anchor">
                Product
              </Link> */}
              <Link to="/bookacall" className="footer-anchor">
                Book a Call
              </Link>
              {/* <Link to="*" className="footer-anchor">
                Member Login
              </Link> */}
              <Link to="/PrivacyPolicy" className="footer-anchor">
                Privacy Policy
              </Link>
              <Link to="/disclaimer" className="footer-anchor">
                Disclaimer
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
