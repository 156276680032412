/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { InformationGridForEmployee } from "./InformationGridForEmployee";
import { TableGridForAllDataForEmployee } from "./TableGridForAllDataForEmployee";
import { HorizontalTabsForEmployee } from "./HorizontalTabsForEmployee";
import { Box, Button, Paper, Grid } from "@mui/material";
import DropDownFiles from "./DropDownFiles";
import ZohoLinks from "./ZohoLinks";
import Carousel from "react-material-ui-carousel";
import { useQuery } from "react-query";
import { listOfAllEmployeeFiles } from "../../api/Api";

const EmployeeDashBoard = () => {
  const { data } = useQuery(
    "getFile",
    () => listOfAllEmployeeFiles("FOCUS_PRODUCT"),
    {
      staleTime: Infinity,
    }
  );

  function Item(props: any) {
    return (
      <Paper>
        <img
          src={props.item.filePath}
          alt="Neo fin desk"
          width={500}
          height={250}
        />{" "}
        {props.i}
      </Paper>
    );
  }

  return (
    <Box sx={{ backgroundColor: "#f9fafb", margin: "-1%" }}>
      <Box sx={{ margin: "-1% 1% 0% 1%" }}>
        <InformationGridForEmployee />
        <Box sx={{ marginTop: 2 }}>
          <Grid container justifyContent={"space-between"} rowSpacing={3}>
            <Grid item xs={12} md={3} sm={12}>
              <DropDownFiles />
            </Grid>
            <Grid item xs={12} md={5} sm={12}>
              <Carousel sx={{ width: "100%" }} navButtonsAlwaysVisible>
                {data &&
                  data.data.reportResponseDTO.map((item: any, i: any) => (
                    <Item key={i} item={item} />
                  ))}
              </Carousel>
            </Grid>
            <Grid item xs={12} md={3} sm={12}>
              <ZohoLinks />
            </Grid>
          </Grid>
        </Box>
        {/* <HorizontalTabsForEmployee/> */}
        <TableGridForAllDataForEmployee />
      </Box>
    </Box>
  );
};

export default EmployeeDashBoard;
