import {
  Container,
  Box,
  Typography,
  IconButton,
  Snackbar,
  Button,
  CircularProgress,
  Modal,
  Fade,
  Backdrop,
  Grid,
  styled,
  TablePagination,
  Link,
  Autocomplete,
  TextField,
  SelectChangeEvent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  InputBase,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { withParam } from "../../utils/Router.Helper";
import { useQuery } from "react-query";
import { fetchFixedIncData, GetAllBonds } from "../../api/Api";
import AddProduct from "./AIF/AddAIFProduct";
import FilterFixedInc from "../Filters/FilterFixedInc";
import { bondData } from "../../Constants/bondData";
import AddIcon from "@mui/icons-material/Add";
import { Resolver, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { bondEnum } from "../../Constants/bondEnum";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { convertDateDMY } from "../../utils/Helper";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AddToProposalModal from "./AddToProposalModal";
import { Navigate, useNavigate } from "react-router-dom";

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    fontWeight: "600",
    padding: "1",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "1",
  },
}));

interface Column {
  id:
    | "name"
    | "coupon"
    | "maturity"
    | "rating"
    | "yield"
    | "yieldCall"
    | "price"
    | "action"
    | "isin";
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

const columns: Column[] = [
  {
    id: "isin",
    label: "ISIN",
    minWidth: 100,
    align: "left",
  },
  { id: "name", label: "Issuer Name", minWidth: 200, align: "left" },
  { id: "coupon", label: "Coupon(%)", minWidth: 70, align: "left" },
  {
    id: "maturity",
    label: "Maturity",
    minWidth: 100,
    align: "left",
  },
  {
    id: "rating",
    label: "Rating",
    minWidth: 200,
    align: "left",
  },
  {
    id: "yield",
    label: "YTM",
    minWidth: 70,
    align: "left",
  },
  {
    id: "yieldCall",
    label: "YTC",
    minWidth: 70,
    align: "left",
  },
  {
    id: "price",
    label: "Price",
    align: "left",
  },
  {
    id: "action",
    label: "Add To Proposal",
    align: "left",
  },
];

const FixedIncome = () => {
  const navigate = useNavigate();

  const [purposeOption, setPurposeOption] = React.useState<string[]>([]);
  const [tenureOption, setTenureOption] = React.useState<any>("");
  const [yeildOption, setYeildOption] = React.useState<string[]>([]);
  const [issuerOption, setIssuerOption] = React.useState<string[]>([]);
  const [investmentOption, setInvestmentOption] = React.useState<string[]>([]);
  const [ratingOption, setRatingOption] = React.useState<string[]>([]);
  const [paymentOption, setPaymentOption] = React.useState<string[]>([]);

  const [securedOption, setsecuredOption] = React.useState<any>("");
  const [seniorityOption, setseniorityOption] = React.useState<any>("");
  const [intPayOption, setintPayOption] = React.useState<string[]>([]);
  const [modeOption, setModeOption] = React.useState<any>("");
  const [priceLevOption, setpriceLevOption] = React.useState<any>("");
  const [callPutOption, setCallPutOption] = React.useState<any>("");
  const [addTierOption, setAddTierOption] = React.useState<any>("");
  const [faceValOption, setfaceValOption] = React.useState<any>("");
  const [issuerNameOption, setIssuerNameOption] = React.useState<any>("");

  const [yields, setYields] = React.useState("");
  const [investmentAmount, setInvestmentAmount] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getApiData = async () => {
    axios
      .get(
        `filter/search?interestPaymentFrequency=${intPayOption}&securedUnsecured=${securedOption}&callPutDate=${callPutOption}&faceValue=${faceValOption}&issuerType=${issuerOption}&modeOfIssuance=${modeOption}&rating=${encodeURIComponent(
          ratingOption.toString()
        )}&seniorityOfRepayment=${seniorityOption}&tier=${addTierOption}${yields}${investmentAmount}${tenureOption}${priceLevOption}${issuerNameOption}`
      )
      .then((res: any) => {
        setFilteredData(res?.data?.searchfilterList);
        console.log("Toast on filter--", res);
      })
      .catch((error: any) => {
        console.error("Error", error);
      });
  };

  useEffect(() => {
    getApiData();
  }, [
    intPayOption,
    securedOption,
    callPutOption,
    faceValOption,
    intPayOption,
    issuerOption,
    modeOption,
    ratingOption,
    seniorityOption,
    addTierOption,
    yields,
    investmentAmount,
    tenureOption,
    priceLevOption,
  ]);

  useEffect(() => {
    const delay = 1000;
    const debounce = setTimeout(() => {
      getApiData();
    }, delay);
    return () => {
      clearInterval(debounce);
    };
  }, [issuerNameOption]);

  console.log("Filtered Data", filteredData);

  const rows = [
    filteredData.map((item: any) => {
      return {
        name: item.issuerName,
        coupon: item.coupon,
        maturity: item.maturityDate,
        rating: item.rating1,
        yield: 0,
        price: 0,
      };
    }),
  ];

  console.log("Rows", rows);

  // Filter functions

  const handlePurposeChange = (event: any) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setPurposeOption([...purposeOption, value]);
    } else {
      setPurposeOption(purposeOption.filter((item) => item !== value));
    }
  };

  const handleSecureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsecuredOption((event.target as HTMLInputElement).value);
  };

  const handlePriceLevelChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setpriceLevOption((event.target as HTMLInputElement).value);
  };

  const handleTenureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTenureOption((event.target as HTMLInputElement).value);
  };

  const handleSeniorityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setseniorityOption((event.target as HTMLInputElement).value);
  };

  const handleCallPutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCallPutOption((event.target as HTMLInputElement).value);
  };

  const handleModeOfIssuanceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModeOption((event.target as HTMLInputElement).value);
  };

  const handleTierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddTierOption((event.target as HTMLInputElement).value);
  };

  const handleYeildChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYields((event.target as HTMLInputElement).value);
  };

  const handleInvestmentAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInvestmentAmount((event.target as HTMLInputElement).value);
  };

  const handleIntPayChange = (event: any) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setintPayOption([...intPayOption, value]);
    } else {
      setintPayOption(intPayOption.filter((item) => item !== value));
    }
  };

  const handleFaceValChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setfaceValOption((event.target as HTMLInputElement).value);
  };

  const handleRatingChange = (event: any) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setRatingOption([...ratingOption, value]);
    } else {
      setRatingOption(ratingOption.filter((item) => item !== value));
    }
  };

  const handleIssuerChange = (event: any) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setIssuerOption([...issuerOption, value]);
    } else {
      setIssuerOption(issuerOption.filter((item) => item !== value));
    }
  };

  const handleSearch = (event: any) => {
    setIssuerNameOption("&issuerName=" + event.target.value);
  };

  const filterDownloadPFD = async () => {
    await axios({
      url: `filter/getBondListInPDF?interestPaymentFrequency=${intPayOption}&securedUnsecured=${securedOption}&callPutDate=${callPutOption}&faceValue=${faceValOption}&issuerType=${issuerOption}&modeOfIssuance=${modeOption}&rating=${ratingOption}&seniorityOfRepayment=${seniorityOption}&tier=${addTierOption}${yields}${investmentAmount}${tenureOption}${priceLevOption}${issuerNameOption}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link: any = document.createElement("a");
      link.href = url;
      var date1 = new Date();
      link.setAttribute(
        "download",
        "Filter_Bond_PDF_" +
          ("0" + date1.getDate()).slice(-2) +
          "_" +
          ("0" + (date1.getMonth() + 1)).slice(-2) +
          "_" +
          date1.getFullYear() +
          ".pdf"
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  return (
    <>
      <Box textAlign={"end"}>
        <Grid></Grid>
      </Box>
      <Container maxWidth={"xl"}>
        <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "1%" }}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              {/* Filter */}
              {/* <FilterFixedInc/> */}

              <div className="filter_container filter_container-filter-aif-main">
                <div className="filter_dropdowns">
                  <Paper
                    sx={{
                      height: "47px",
                      width: "100%",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        letterSpacing: "1px",
                        fontWeight: "600",
                        padding: "10px",
                        color: "primary",
                        marginLeft: "5px",
                      }}
                    >
                      Filters
                    </Typography>
                    <TuneRoundedIcon
                      sx={{
                        fontSize: "1rem",
                        marginTop: "15px",
                        color: "primary",
                      }}
                    />
                  </Paper>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        sx={{
                          color: "#3c3b3b",
                          //   marginLeft: "8px",
                          fontSize: "0.9rem",
                          fontWeight: "500",
                          letterSpacing: "1px",
                        }}
                      >
                        Tenure Remaining
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <RadioGroup
                          value={tenureOption}
                          onChange={handleTenureChange}
                        >
                          <Button
                            size="small"
                            onClick={() => setTenureOption("")}
                          >
                            Clear
                          </Button>

                          <FormControlLabel
                            control={<Radio />}
                            value={"&tenureYearMax=3&tenureYearMin=0"}
                            label={"< 3 Years"}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={"&tenureYearMax=3&tenureYearMin=7"}
                            label={"3 - 7 Years"}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={"&tenureYearMax=&tenureYearMin=7"}
                            label={"> 7 Years"}
                          />

                          {/* <FormControlLabel control={<Radio />} value={"Private_Placement"} label="Private Placement"/> */}
                        </RadioGroup>
                        {/* <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="< 3 Years" />
                  <FormControlLabel   
                    control={<Checkbox />}
                    label="3 - 7 Years"
                  />
                  <FormControlLabel control={<Checkbox />} label="> 7 Years" />
                </FormGroup> */}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <StoreRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
                      <Typography
                        sx={{
                          color: "#3c3b3b",
                          //   marginLeft: "8px",
                          fontSize: "0.9rem",
                          fontWeight: "500",
                          letterSpacing: "1px",
                        }}
                      >
                        Yield
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <RadioGroup value={yields} onChange={handleYeildChange}>
                          <Button size="small" onClick={() => setYields("")}>
                            Clear
                          </Button>

                          <FormControlLabel
                            value="&yieldMax=7"
                            control={<Radio />}
                            label="< 7%"
                          />
                          <FormControlLabel
                            value="&yieldMax=10&yieldMin=7"
                            control={<Radio />}
                            label="7 - 10%"
                          />
                          <FormControlLabel
                            value="&yieldMin=10"
                            control={<Radio />}
                            label="> 10%"
                          />
                        </RadioGroup>
                        {/* <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="< 7%" />
                  <FormControlLabel control={<Checkbox />} label="7 - 10%" />
                  <FormControlLabel control={<Checkbox />} label="> 10%" />
                </FormGroup> */}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <SendRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
                      <Typography
                        sx={{
                          color: "#3c3b3b",
                          //   marginLeft: "8px",
                          fontSize: "0.9rem",
                          fontWeight: "500",
                          letterSpacing: "1px",
                        }}
                      >
                        Issuer Type
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <FormGroup>
                          {bondEnum.IssuerTypeEnum.map((it: any) => (
                            <FormControlLabel
                              control={<Checkbox />}
                              value={it.value}
                              label={it.label}
                              onChange={handleIssuerChange}
                            />
                          ))}
                          {/* <FormControlLabel control={<Checkbox />} value="NBFC" label="NBFC" onChange={handleIssuerChange} />
                  <FormControlLabel control={<Checkbox />} value="Bank_Bonds" label="Bank Bonds" onChange={handleIssuerChange} />
                  <FormControlLabel control={<Checkbox />} value="High_Yeild" label="High Yield" onChange={handleIssuerChange} />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="State Government Guarenty"
                    value={"State_Government_Guarenty"}
                    onChange={handleIssuerChange}
                  /> */}
                        </FormGroup>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <SendRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
                      <Typography
                        sx={{
                          color: "#3c3b3b",
                          //   marginLeft: "8px",
                          fontSize: "0.9rem",
                          fontWeight: "500",
                          letterSpacing: "1px",
                        }}
                      >
                        Investment Amount
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <RadioGroup
                          value={investmentAmount}
                          onChange={handleInvestmentAmountChange}
                        >
                          <Button
                            size="small"
                            onClick={() => setInvestmentAmount("")}
                          >
                            Clear
                          </Button>
                          <FormControlLabel
                            value="&investmentAmountMax=1000000"
                            control={<Radio />}
                            label="< 10 Lacs"
                          />
                          <FormControlLabel
                            value="&investmentAmountMax=5000000&investmentAmountMin=1000000"
                            control={<Radio />}
                            label="10 - 50 Lacs"
                          />
                          <FormControlLabel
                            value="&investmentAmountMin=5000000"
                            control={<Radio />}
                            label=" > 50 Lacs"
                          />
                        </RadioGroup>
                        {/* <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="< 10 Lacs" />
                  <FormControlLabel control={<Checkbox />} label="10 - 50 Lacs" />
                  <FormControlLabel control={<Checkbox />} label=" > 50 Lacs" />
                </FormGroup> */}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <SendRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
                      <Typography
                        sx={{
                          color: "#3c3b3b",
                          //   marginLeft: "8px",
                          fontSize: "0.9rem",
                          fontWeight: "500",
                          letterSpacing: "1px",
                        }}
                      >
                        Rating
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            value="AAA"
                            label="AAA"
                            onChange={handleRatingChange}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            value="AA+"
                            label="AA+"
                            onChange={handleRatingChange}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            value="AA-"
                            label="AA-"
                            onChange={handleRatingChange}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            value="A+"
                            label="A+"
                            onChange={handleRatingChange}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            value="A-"
                            label="A-"
                            onChange={handleRatingChange}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            value="BBB+"
                            label="BBB+ and below"
                            onChange={handleRatingChange}
                          />
                        </FormGroup>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <SendRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
                      <Typography
                        sx={{
                          color: "#3c3b3b",
                          //   marginLeft: "8px",
                          fontSize: "0.9rem",
                          fontWeight: "500",
                          letterSpacing: "1px",
                        }}
                      >
                        Payment Frequency
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Annual"
                            value="ANNUAL"
                            onChange={handleIntPayChange}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Monthly"
                            value="MONTHLY"
                            onChange={handleIntPayChange}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Quaterly"
                            value="QUARTERLY"
                            onChange={handleIntPayChange}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Half Yearly"
                            value="Half_Yearly"
                            onChange={handleIntPayChange}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Cumulative"
                            value="CUMULATIVE"
                            onChange={handleIntPayChange}
                          />
                        </FormGroup>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <SortRoundedIcon sx={{fontSize:'1.3rem', color:'#827a7a'}}/> */}
                      <Typography
                        sx={{
                          color: "#3c3b3b",
                          //   marginLeft: "8px",
                          fontSize: "0.9rem",
                          fontWeight: "500",
                          letterSpacing: "1px",
                        }}
                      >
                        Additional Filters
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              color: "#3c3b3b",
                              marginLeft: "8px",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              letterSpacing: "1px",
                            }}
                          >
                            Secured
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RadioGroup
                            value={securedOption}
                            onChange={handleSecureChange}
                          >
                            <Button
                              size="small"
                              onClick={() => setsecuredOption("")}
                            >
                              Clear
                            </Button>
                            {bondEnum.SecuredUnsecuredEnum.map((it: any) => (
                              <FormControlLabel
                                control={<Radio />}
                                value={it.value}
                                label={it.label}
                              />
                            ))}
                            {/* <FormControlLabel control={<Radio />} value="Unsecured" label="No" /> */}
                          </RadioGroup>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              color: "#3c3b3b",
                              marginLeft: "8px",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              letterSpacing: "1px",
                            }}
                          >
                            Seniority
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RadioGroup
                            value={seniorityOption}
                            onChange={handleSeniorityChange}
                          >
                            <Button
                              size="small"
                              onClick={() => setseniorityOption("")}
                            >
                              Clear
                            </Button>
                            {bondEnum.RepaymentEnum.map((it: any) => (
                              <FormControlLabel
                                control={<Radio />}
                                value={it.value}
                                label={it.label}
                              />
                            ))}
                            {/* <FormControlLabel control={<Radio />} value="Subordinate" label="Subordinate" /> */}
                          </RadioGroup>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              color: "#3c3b3b",
                              marginLeft: "8px",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              letterSpacing: "1px",
                            }}
                          >
                            Guarantee
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label={"Central Government"}
                              value={"CENTRAL_GOVERNMENT"}
                              onChange={handleIntPayChange}
                            />
                            <FormControlLabel
                              control={<Checkbox />}
                              label={"State Government"}
                              value={"STATE_GOVERNMENT,"}
                              onChange={handleIntPayChange}
                            />
                            <FormControlLabel
                              control={<Checkbox />}
                              label={"No Guarantee"}
                              value={"NO"}
                              onChange={handleIntPayChange}
                            />

                            {/* <FormControlLabel control={<Checkbox />} label="Quaterly" value={"Quarterly"} onChange={handleIntPayChange}/>
                    <FormControlLabel control={<Checkbox />} label="Half Yearly" value={"Half_Yearly"} onChange={handleIntPayChange}/>
                    <FormControlLabel control={<Checkbox />} label="Annually" value={"Annual"} onChange={handleIntPayChange}/> */}
                          </FormGroup>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              color: "#3c3b3b",
                              marginLeft: "8px",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              letterSpacing: "1px",
                            }}
                          >
                            Mode of Issuance
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RadioGroup
                            value={modeOption}
                            onChange={handleModeOfIssuanceChange}
                          >
                            <Button
                              size="small"
                              onClick={() => setModeOption("")}
                            >
                              Clear
                            </Button>
                            {bondEnum.ModeOfIssuanceEnum.map((it: any) => (
                              <FormControlLabel
                                control={<Radio />}
                                value={it.value}
                                label={it.label}
                              />
                            ))}
                            {/* <FormControlLabel control={<Radio />} value={"Private_Placement"} label="Private Placement"/> */}
                          </RadioGroup>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              color: "#3c3b3b",
                              marginLeft: "8px",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              letterSpacing: "1px",
                            }}
                          >
                            Price Level
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RadioGroup
                            value={priceLevOption}
                            onChange={handlePriceLevelChange}
                          >
                            <Button
                              size="small"
                              onClick={() => setpriceLevOption("")}
                            >
                              Clear
                            </Button>

                            <FormControlLabel
                              control={<Radio />}
                              value={"&priceLevel=100"}
                              label={"Premium"}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              value={"&priceLevel=99"}
                              label={"Discount"}
                            />

                            {/* <FormControlLabel control={<Radio />} value={"Private_Placement"} label="Private Placement"/> */}
                          </RadioGroup>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              color: "#3c3b3b",
                              marginLeft: "8px",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              letterSpacing: "1px",
                            }}
                          >
                            Call/Put Option
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RadioGroup
                            value={callPutOption}
                            onChange={handleCallPutChange}
                          >
                            <Button
                              size="small"
                              onClick={() => setCallPutOption("")}
                            >
                              Clear
                            </Button>
                            {bondEnum.CallPutDateEnum.map((it: any) => (
                              <FormControlLabel
                                control={<Radio />}
                                value={it.value}
                                label={it.label}
                              />
                            ))}
                          </RadioGroup>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              color: "#3c3b3b",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              letterSpacing: "1px",
                            }}
                          >
                            Additional Tier I Bonds
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RadioGroup
                            value={addTierOption}
                            onChange={handleTierChange}
                          >
                            <Button
                              size="small"
                              onClick={() => setAddTierOption("")}
                            >
                              Clear
                            </Button>
                            <FormControlLabel
                              control={<Radio />}
                              value="ADDITIONAL_TIER_1"
                              label="Yes"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              value="TIER_1,TIER_2"
                              label="No"
                            />
                          </RadioGroup>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              color: "#3c3b3b",
                              marginLeft: "8px",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              letterSpacing: "1px",
                            }}
                          >
                            Face Value
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RadioGroup
                            value={faceValOption}
                            onChange={handleFaceValChange}
                          >
                            <Button
                              size="small"
                              onClick={() => setfaceValOption("")}
                            >
                              Clear
                            </Button>
                            <FormControlLabel
                              control={<Radio />}
                              label="1000"
                              value={"1000"}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="10,000"
                              value={"10000"}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="1,00,000"
                              value={"100000"}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="5,00,000"
                              value={"500000"}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="> 10,00,000"
                              value={"1000000"}
                            />
                          </RadioGroup>
                        </AccordionDetails>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Grid>
            <Grid item md={9}>
              <TableContainer component={Paper}>
                <Grid
                  item
                  md={12}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                      m: 1,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Bond By Issuer Name"
                      onChange={handleSearch}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    ></IconButton>
                  </Paper>

                  <Button onClick={filterDownloadPFD}>
                    <CloudDownloadIcon />
                  </Button>
                </Grid>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ top: 57, width: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData &&
                      filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row: any) => {
                          return (
                            <TableRow key={row.isinNumber}>
                              <StyledTableCell
                                align={"left"}
                                onClick={() => {
                                  navigate("/BondDetails/" + row.isinNumber);
                                }}
                                sx={{
                                  textDecoration: "underLine",
                                  cursor: "pointer",
                                }}
                              >
                                {row.isinNumber}
                              </StyledTableCell>
                              <StyledTableCell align={"left"}>
                                {row.issuerName}
                              </StyledTableCell>
                              <StyledTableCell align={"left"}>
                                {row.coupon}
                              </StyledTableCell>
                              <StyledTableCell align={"left"}>
                                {convertDateDMY(row.maturityDate.slice(0, 10))}
                              </StyledTableCell>
                              <StyledTableCell align={"left"}>
                                {row.rating1}
                              </StyledTableCell>
                              <StyledTableCell align={"left"}>
                                {row.saleYtm}
                              </StyledTableCell>
                              <StyledTableCell align={"left"}>
                                {row.saleYtc}
                              </StyledTableCell>
                              <StyledTableCell align={"left"}>
                                {row.salePrice}
                              </StyledTableCell>
                              <StyledTableCell align={"left"}>
                                <AddToProposalModal data={row} />
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 30, 50]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default withParam(FixedIncome);
