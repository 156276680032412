import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { Modal, TextField } from "@mui/material";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import Scroll from "./Scroll";
import WhyUs from "./WhyUs";
import FinancialProducts from "./FinancialProducts";
import Blogs from "./Blogs";
import InvestmentProd from "./InvestmentProd";
import AboutUs from "./AboutUs";
import BookACall from "./BookACall/BookACall";
import PartnersSlider from "./PartnersSlider";
import Footer from "./Footer";
import FooterDisclaimer from "./FooterDisclaimer";
import FooterCopyright from "./FooterCopyright";
import DistributerSlider from "./DistributerSlider";
import WhyNfd from "./WhyNfd";

export const Home = (props: any) => {
  let navigate = useNavigate();
  const { window } = props;

  const drawerWidth = 240;
  const navItems = ["Home", "About", "Contact"];

  const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [Redi, setRedi] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const setRedirect = (url: any) => {
    navigate(url);
  };

  if (Redi != "") {
    window.location.href = Redi + "";
  }

  console.log("Redirect", Redi);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Neo Fin Desk
      </Typography>

      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Scroll />
      <WhyNfd/>
      {/* <WhyUs /> */}
      {/* <FinancialProducts /> */}
      <InvestmentProd/>
      <BookACall/>
      <PartnersSlider/>
      <DistributerSlider/>
      <Blogs />
      <AboutUs />
      <Footer/>
      <FooterDisclaimer/>
      <FooterCopyright/>
    </>
  );
};
