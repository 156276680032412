/* eslint-disable no-sequences */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withParam } from "../utils/Router.Helper";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  Link,
  TablePagination,
  Tooltip,
  IconButton,
  Modal,
  InputAdornment,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { CinRegex, GstRegex, PANRegex } from "../utils/Regex";
import { useQuery } from "react-query";
import {
  GetAllClient,
  GetBrokeragePayoutByCompanyMasterCode,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  product_listOfAllProductType,
} from "../api/Api";
import { Bounce, toast } from "react-toastify";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Viewer } from "@react-pdf-viewer/core";
import States from "../Constants/States";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CompanyProfileView = (props: any) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const [Company, setCompany] = useState<any>([]);
  const [Refresh, setRefresh] = useState<any>(true);
  const [edit, setedit] = useState(true);
  const [Register, setRegister] = useState<any>();
  const [Gst, setGst] = useState<any>();
  const [pancard, setPancard] = useState<any>();
  const [cancleCheque, setCancleCheque] = useState<any>();
  const [companyLogo, setCompanyLogo] = useState<any>();
  const [ProductList, setProductList] = useState([]);
  const [CompanyWiseProduct, setCompanyWiseProduct] = useState([]);
  const [productlist, setproductlist] = useState("");
  const [companyCode, setCompanyCode] = useState(props.params.CompanyCode);
  const [custPayout, setcustPayout] = useState("");
  const [employeePayout, setEmployeePayout] = useState("");
  const [IVPayout, setIVPayout] = useState("");
  const [isChecked, setIsChecked] = useState();
  const [DistPayoutRadio, setDistPayoutRadio] = useState("PERCENTAGE");
  const [BrokerPayoutRadio, setBrokerPayoutRadio] = useState("PERCENTAGE");
  const [EmployeePayoutRadio, setEmployeePayoutRadio] = useState("PERCENTAGE");
  const [BrorageProductTypeCode, setBrorageProductTypeCode] = useState("");
  const [brokrageProduct, setbrokrageProduct] = useState<any>([]);
  const [purposeOption, setPurposeOption] = React.useState<string[]>([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [compType, setCompType] = useState<any>(Company?.compType);
  const [employee, setEmployee] = useState(null);
  const [Clients, setClients] = useState([]);

  const [employeeCompanyList, setEmployeeCompanyList] = useState<any>([]);
  const [documentFile, setDocumentFile] = useState<any>([]);
  const [documentFileId, setDocumentFileId] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const handleCloseViewClient = () => setOpen(false);

  const [empCompanyCode, setEmpCompanyCode] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [selectedState, setSelectedState] = useState<any>("");
  const [isDistributorFocused, setIsDistributorFocused] = useState(false);
  const [isBrokerFocused, setIsBrokerFocused] = useState(false);
  const [fetchState, setFetchState] = useState("");
  const [isRMFocused, setIsRMFocused] = useState(false);
  const [isDistFocused, setIsDistFocused] = useState(false);
  // ++++++++++++++++++++++++++++++++
  // RM COMMISION SHARING
  const [rmPercentage, setRmPercentage] = useState("");
  // DISTRIBUTOR COMMISION SHARING
  const [distPercentage, setDistPercentage] = useState("");
  // const brokrageProductRef = useRef(brokrageProduct);
  // const IVPayoutRef = useRef(IVPayout);
  // const employeePayoutRef = useRef(employeePayout);
  // const custPayoutRef = useRef(custPayout);

  const handleFocusDistributor = () => {
    setIsDistributorFocused(true);
    // setIsBrokerFocused(false);
    setIsRMFocused(false);
    setIsDistFocused(false);
  };

  // const handleFocusBroker = () => {
  //   setIsBrokerFocused(true);
  //   setIsDistributorFocused(false);
  // };

  const handleFocusRM = () => {
    setIsRMFocused(true);
    setIsDistFocused(false);
    setIsDistributorFocused(false);
  };

  const handleFocusDist = () => {
    setIsDistFocused(true);
    setIsRMFocused(false);
    setIsDistributorFocused(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleLogoChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const { data: listOfAllProductType } = useQuery(
    "ListOfAllProductTypes",
    product_listOfAllProductType,
    {
      onSuccess: (data: any) => {
        setProductList(data.data.productType);
      },
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    companyCode !== "" &&
      axios
        .get("sourceDestination/sourceValue?sourceValue=" + companyCode)
        .then((res: any) => setCompanyWiseProduct(res.data.sourceDes));
  }, []);

  useEffect(() => {
    axios
      .get("company/companyDetail/" + props.params.CompanyCode)
      .then((res: any) => {
        setCompany(res.data.companies[0]);
        setEmployee(res.data.companies[0].rmUserCode);
        setIsChecked(res.data.companies[0].viewLogo);
        setPurposeOption(res.data.companies[0].products.split(","));
        setSelectedCountry(
          States.countries.find(
            (country) => country.country === res.data.companies[0].country
          )
        );
        // setting initial values of country and state to setValue of useForm
        setValue("country", res.data.companies[0].country);
        setValue("state", res.data.companies[0].state);
        setFetchState(res.data.companies[0].state);
        axios
          .get(
            "Brokers/brokerBankDetails?brokerMasterCode=" +
              res.data.companies[0].brokerMasterCode
          )
          .then((res: any) => setBankDetails(res.data.bankDetailsDTOS));
      });

    axios
      .get(
        "company/listOfManufactureNDistributorCompanies/companyTypeCode?companyTypeCode=E"
      )
      .then((res: any) => {
        setEmployeeCompanyList(res.data.companies);
        setEmpCompanyCode(res.data.companies[0]?.companyMasterCode);
      });
  }, [Refresh]);

  useEffect(() => {
    setCompType(Company.compType);
  }, [Company]);

  useEffect(() => {
    empCompanyCode &&
      axios
        .get(
          "employeeProfile/employeeByCompany?companyMasterCode=" +
            empCompanyCode
        )
        .then((res: any) => {
          setEmployeeData(res.data.employeeProfileDTOS);
        });
  }, [empCompanyCode]);

  const Active_Inactive_status = () => {
    axios
      .put("company/deleteCompany/" + Company.companyMasterCode)
      .then(() => setRefresh(!Refresh));
  };

  const editDetails = () => {
    setedit(false);
  };

  const { data } = useQuery(
    "GetBrokeragePayoutByCompanyMasterCode",
    () => GetBrokeragePayoutByCompanyMasterCode(props.params.CompanyCode),
    {
      onSuccess: (data) => {
        setbrokrageProduct(data.data.companyBrokerage);
      },
      staleTime: Infinity,
    }
  );

  const { data: clientData } = useQuery(
    "getAllClients",
    () => GetAllClient(props.params.CompanyCode),
    {
      onSuccess: (data: any) => {
        const newData = data.data.clients.map((item: any, index: any) => {
          return { ...item, SrNo: index + 1 };
        });
        setClients(newData);
      },
      staleTime: Infinity,
    }
  );

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany"],
    () => GetListOfUserByCompanyMasterCodeAndUserTypeCode("E", empCompanyCode),
    {
      enabled: !!empCompanyCode,
      staleTime: Infinity,
    }
  );

  // useEffect(() => {
  //   // Check if custPayout has changed
  //   if (
  //     custPayout !== custPayoutRef.current
  //     // || IVPayout !== IVPayoutRef.current
  //   ) {
  //     custPayoutRef.current = custPayout;
  //     // IVPayoutRef.current = IVPayout;

  //     setBrokerPayoutRadio(
  //       brokrageProduct.filter(
  //         (item: any) => item.productTypeCode === BrorageProductTypeCode
  //       )[0]?.typeOfBroForIV
  //         ? brokrageProduct.filter(
  //             (item: any) => item.productTypeCode === BrorageProductTypeCode
  //           )[0]?.typeOfBroForIV
  //         : BrokerPayoutRadio
  //     );
  //     setEmployeePayoutRadio(
  //       brokrageProduct.filter(
  //         (item: any) => item.productTypeCode === BrorageProductTypeCode
  //       )[0]?.typeOfBrokerageForEmployee
  //         ? brokrageProduct.filter(
  //             (item: any) => item.productTypeCode === BrorageProductTypeCode
  //           )[0]?.typeOfBrokerageForEmployee
  //         : EmployeePayoutRadio
  //     );

  //     setDistPayoutRadio(
  //       brokrageProduct.filter(
  //         (item: any) => item.productTypeCode === BrorageProductTypeCode
  //       )[0]?.typeOfBroForCustomer
  //         ? brokrageProduct.filter(
  //             (item: any) => item.productTypeCode === BrorageProductTypeCode
  //           )[0]?.typeOfBroForCustomer
  //         : DistPayoutRadio
  //     );
  //   }
  // }, [brokrageProduct, IVPayout, employeePayout]);

  // radio for distributor payout
  const handleDistributorPayoutRadio = (e: any) => {
    const selectedTypeOfBroForCustomer = brokrageProduct.filter(
      (item: any) => item.productTypeCode === BrorageProductTypeCode
    ).typeOfBroForCustomer;

    const selectedValue = selectedTypeOfBroForCustomer
      ? selectedTypeOfBroForCustomer
      : e.target.value;

    // Reset the TextField value when changing radio buttons
    if (selectedValue !== DistPayoutRadio) {
      setcustPayout("");
    }
    setDistPayoutRadio(selectedValue);
  };

  // radio for broker payout
  const handleBrokerPayoutRadio = (e: any) => {
    const selectedTypeOfBroForCustomer = brokrageProduct.filter(
      (item: any) => item.productTypeCode === BrorageProductTypeCode
    ).typeOfBroForIV;

    const selectedValue = selectedTypeOfBroForCustomer
      ? selectedTypeOfBroForCustomer
      : e.target.value;

    // Reset the TextField value when changing radio buttons
    if (selectedValue !== BrokerPayoutRadio) {
      setIVPayout("");
    }
    setBrokerPayoutRadio(selectedValue);
  };

  const resolver: any = async (values: any) => {
    return {
      values: !values.companyName ? {} : values,
      errors: !values.companyName
        ? {
            companyName: {
              type: "required",
              message: "This is required.",
            },
          }
        : values.companyName.length < 3
        ? {
            companyName: {
              type: "required",
              message: "Enter a valid Company Name.",
            },
          }
        : !values.emailId
        ? {
            emailId: {
              type: "required",
              message: "Enter a valid Company Name.",
            },
          }
        : // : !values.cinNumber
        // ? {
        //     cinNumber: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        values.cinNumber !== "" && !CinRegex(values.cinNumber)
        ? {
            cinNumber: {
              type: "required",
              message: "Enter a valid CIN Number.",
            },
          }
        : // : !values.gstNumber
        // ? {
        //     gstNumber: {
        //       type: "required",
        //       message: "This is required.",
        //     },
        //   }
        values.gstNumber !== "" && !GstRegex(values.gstNumber)
        ? {
            gstNumber: {
              type: "required",
              message: "Enter a valid GST Number.",
            },
          }
        : !values.pancard
        ? {
            pancard: {
              type: "required",
              message: "This is required.",
            },
          }
        : !PANRegex(values.pancard)
        ? {
            pancard: {
              type: "required",
              message: "Enter a valid PAN Number.",
            },
          }
        : !values.noOfUser
        ? {
            noOfUser: {
              type: "required",
              message: "This is required.",
            },
          }
        : values.noOfUser > 5
        ? {
            noOfUser: {
              type: "required",
              message: "You Can not Create More Then 5 Users.",
            },
          }
        : !values.country
        ? {
            country: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.state
        ? {
            state: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.addressLine1
        ? {
            addressLine1: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.addressLine2
        ? {
            addressLine2: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.addressLine3
        ? {
            addressLine3: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.pin
        ? {
            pin: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.bankName
        ? {
            bankName: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.branchName
        ? {
            branchName: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.accountNo
        ? {
            accountNo: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.ifscNo
        ? {
            ifscNo: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const resolver2: any = async (values: any) => {
    return {
      values: !values.custBrokerage ? {} : values,
      errors: !values.custBrokerage
        ? {
            custBrokerage: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: resolver,
  });

  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    formState: { errors: error2 },
  } = useForm({
    resolver: resolver2,
  });

  function handleRegisterChange(event: any) {
    setRegister(event.target.files[0]);
  }
  function handleGstChange(event: any) {
    setGst(event.target.files[0]);
  }
  function handlePancardChange(event: any) {
    setPancard(event.target.files[0]);
  }
  function handleCancleChequeChange(event: any) {
    setCancleCheque(event.target.files[0]);
  }
  function handleCompanyLogoChange(event: any) {
    setCompanyLogo(event.target.files[0]);
  }

  const handleChangeProduct = (event: any) => {
    const value = event + "";
    if (!purposeOption.includes(event + "")) {
      setPurposeOption([...purposeOption, value]);
    } else {
      setPurposeOption(purposeOption.filter((item) => item !== value));
    }
  };

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append("registerOfCertificate", Register);
    formData.append("gstNumberDoc", Gst);
    formData.append("pancardDoc", pancard);
    formData.append("cancelCheque", cancleCheque);
    formData.append("companyLogo", companyLogo);
    formData.append(
      "companyDto",
      JSON.stringify({
        ...data,
        companyTypeCode: Company.companyTypeCode,
        companyMasterCode: Company.companyMasterCode,
        viewLogo: isChecked,
        products: purposeOption.toString(),
        compType: compType,
        rmUserCode: employee,
      })
    );
    // toast.promise(
    //   axios.put("company/updateCompany", formData),{
    //     pending: 'Promise is pending',
    //     success: 'Company Update Sucessfully',
    //     error: 'Promise rejected 🤯'
    //   }
    // )
    axios
      .put("company/updateCompany", formData)
      .then(() => {
        toast.success("Company updated successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Bounce,
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        setedit(true);
      })
      .catch((err: any) =>
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        )
      );
  };

  function handleChangePayout(e: any) {
    let custPayout: any =
      brokrageProduct &&
      brokrageProduct
        .filter((item: any) => item.productTypeCode === BrorageProductTypeCode)
        .map((it: any) => it.brokerageValueForCustomer);
    setcustPayout(custPayout[0]);

    let employeePayout: any =
      brokrageProduct &&
      brokrageProduct
        .filter((item: any) => item.productTypeCode === BrorageProductTypeCode)
        .map((it: any) => it.brokerageValueForEmployee);
    setEmployeePayout(employeePayout[0]);

    let IVPayout: any =
      brokrageProduct &&
      brokrageProduct
        .filter((item: any) => item.productTypeCode === BrorageProductTypeCode)
        .map((it: any) => it.brokerageValueForIV);
    setIVPayout(IVPayout);
  }

  const onSubmit2 = () => {
    let companyBrokrage: any =
      data &&
      data.data.companyBrokerage
        .filter((item: any) => item.productName === productlist)
        .map((it: any) => it);

    const UnlistedDtou =
      brokrageProduct &&
      brokrageProduct
        .filter((item: any) => item.productTypeCode == BrorageProductTypeCode)
        .map((it: any) => it.companyBrokerageCode);
    if (UnlistedDtou.length == 0) {
      const UnlistedDto = {
        // companyMasterCode: Company.companyMasterCode,
        // productTypeCode: BrorageProductTypeCode,
        // brokerageValueForIV: IVPayout,
        // brokerageValueForCustomer: custPayout,
        // brokerageValueForEmployee: employeePayout,
        // typeOfBroForCustomer: DistPayoutRadio,
        // typeOfBroForIV: BrokerPayoutRadio,
        // typeOfBrokerageForEmployee: EmployeePayoutRadio,
        companyMasterCode: Company.companyMasterCode,
        productTypeCode: BrorageProductTypeCode,
        brokerageValueForIV: 0,
        brokerageValueForCustomer: custPayout,
        typeOfBroForCustomer: "PERCENTAGE",
        typeOfBroForIV: "PERCENTAGE",
        rmCommSharing: rmPercentage,
        rmCommSharingType: "PERCENTAGE",
        distCommSharing: distPercentage,
        distCommSharingType: "PERCENTAGE",
      };
      axios
        .post("companyBrokerage", UnlistedDto)
        .then(() => toast.success("Company payout added successfully!"))
        .then(() => window.location.reload())
        .catch((err: any) =>
          toast.error(
            err.response.data ? err.response.data.status.message.title : "Error"
          )
        );
    } else {
      const UnlistedDto = {
        // companyBrokerageCode: UnlistedDtou[0],
        // companyMasterCode: Company.companyMasterCode,
        // productTypeCode: BrorageProductTypeCode,
        // typeOfBroForIV: BrokerPayoutRadio,
        // brokerageValueForIV: IVPayout,
        // typeOfBroForCustomer: DistPayoutRadio,
        // brokerageValueForCustomer: custPayout,
        // typeOfBrokerageForEmployee: EmployeePayoutRadio,
        // brokerageValueForEmployee: employeePayout,
        companyBrokerageCode: UnlistedDtou[0],
        companyMasterCode: Company.companyMasterCode,
        productTypeCode: BrorageProductTypeCode,
        typeOfBroForIV: BrokerPayoutRadio,
        brokerageValueForIV: 0,
        typeOfBroForCustomer: DistPayoutRadio,
        brokerageValueForCustomer: custPayout,
        brokerMasterCode: Company?.brokerMasterCode,
        rmCommSharing: rmPercentage,
        rmCommSharingType: "PERCENTAGE",
        distCommSharing: distPercentage,
        distCommSharingType: "PERCENTAGE",
      };
      axios
        .put("companyBrokerage", UnlistedDto)
        .then(() => toast.success("Company payout added successfully!"))
        .then(() => window.location.reload())
        .catch((err: any) =>
          toast.error(
            err.response.data ? err.response.data.status.message.title : "Error"
          )
        );
    }
  };

  const [value, setValues] = React.useState<any>();

  const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    let custPayout: any =
      brokrageProduct &&
      brokrageProduct
        .filter((item: any) => item.productTypeCode == BrorageProductTypeCode)
        .map((it: any) => it.brokerageValueForCustomer);
    setcustPayout(custPayout[0] ? custPayout[0] : "");

    //+++++fetching radio button value of distributor payout+++++
    let distPayoutRadio: any =
      brokrageProduct &&
      brokrageProduct
        .filter((item: any) => item.productTypeCode === BrorageProductTypeCode)
        .map((it: any) => it.typeOfBroForCustomer);
    setDistPayoutRadio(distPayoutRadio[0] ? distPayoutRadio[0] : "");

    //+++++fetching radio button value of broker payout+++++
    let brokerPayoutRadio: any =
      brokrageProduct &&
      brokrageProduct
        .filter((item: any) => item.productTypeCode === BrorageProductTypeCode)
        .map((it: any) => it.typeOfBroForIV);
    setBrokerPayoutRadio(brokerPayoutRadio[0] ? brokerPayoutRadio[0] : "");

    let employeePayout: any =
      brokrageProduct &&
      brokrageProduct
        .filter((item: any) => item.productTypeCode == BrorageProductTypeCode)
        .map((it: any) => it.brokerageValueForEmployee);
    setEmployeePayout(employeePayout[0] ? employeePayout[0] : "");

    let IVPayout: any =
      brokrageProduct &&
      brokrageProduct
        .filter((item: any) => item.productTypeCode == BrorageProductTypeCode)
        .map((it: any) => it.brokerageValueForIV);
    setIVPayout(IVPayout[0] ? IVPayout[0] : "");
    setValues(newValue);

    // set values for RM and Distributor Commision Sharing
    let rmPercentageValue: any =
      brokrageProduct &&
      brokrageProduct
        ?.filter((item: any) => item.productTypeCode === BrorageProductTypeCode)
        .map((it: any) => it.rmCommSharing);
    setRmPercentage(rmPercentageValue[0] ? rmPercentageValue[0] : "");

    let distPercentageValue: any =
      brokrageProduct &&
      brokrageProduct
        ?.filter((item: any) => item.productTypeCode === BrorageProductTypeCode)
        .map((it: any) => it.distCommSharing);
    setDistPercentage(distPercentageValue[0] ? distPercentageValue[0] : "");
  };

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const viewDocument = (fileName: any) => {
    axios.get("documentupload?documentId=" + fileName).then((res: any) => {
      setDocumentFile(res?.data?.documentFilePath);
      setDocumentFileId(fileName);
      setOpen(true);
    });
  };

  const downloadDocument = (documentId: any) => {
    if (documentId) {
      axios.get("documentupload?documentId=" + documentId).then((res: any) => {
        axios({
          url: "documentupload/download?documentId=" + documentId,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.data.documentFileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      });
    }
  };

  return (
    <div>
      <Box paddingTop="2%"></Box>
      {Company.length !== 0 && (
        <>
          <Container maxWidth="xl">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                id="keep-mounted-modal-description"
                sx={{ "& .MuiTextField-root": { m: 1 } }}
              >
                <Grid item>
                  <Typography variant="h5" marginTop={2} marginBottom={3}>
                    Company Details
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={Company.compType}
                      sx={{ marginLeft: "20px" }}
                    >
                      <label>Company Type</label>
                      <Stack direction={"row"} spacing={3}>
                        <FormControlLabel
                          value="INDIVIDUAL"
                          // {...register("compType")}
                          control={<Radio />}
                          label="Individual"
                          disabled={edit}
                          onChange={(e: any) => setCompType(e.target.value)}
                        />
                        <FormControlLabel
                          value="CORPORATE"
                          // {...register("compType")}
                          control={<Radio />}
                          label="Corporate"
                          disabled={edit}
                          onChange={(e: any) => setCompType(e.target.value)}
                        />
                      </Stack>
                    </RadioGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Company Name"
                      variant="outlined"
                      fullWidth
                      defaultValue={Company.companyName}
                      className={edit ? "textDisable" : ""}
                      error={errors.companyName ? true : false}
                      helperText={
                        !!errors.companyName && errors.companyName.message + ""
                      }
                      InputProps={{ readOnly: edit }}
                      {...register("companyName")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="CIN Number"
                      variant="outlined"
                      fullWidth
                      defaultValue={
                        Company.cinNumber
                          ? Company.cinNumber.trim()
                          : Company.cinNumber
                      }
                      className={edit ? "textDisable" : ""}
                      error={errors.cinNumber ? true : false}
                      helperText={
                        !!errors.cinNumber && errors.cinNumber.message + ""
                      }
                      InputProps={{
                        readOnly: edit,
                        // endAdornment: (
                        //   <Tooltip title="Your tooltip text here" arrow>
                        //     <InputAdornment position="end">
                        //       <IconButton>
                        //         <VisibilityIcon
                        //           sx={{ pb: 0, fontSize: "1rem" }}
                        //           color="info"
                        //         />
                        //       </IconButton>
                        //     </InputAdornment>
                        //   </Tooltip>
                        // ),
                      }}
                      {...register("cinNumber")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="No of Users"
                      type="Number"
                      variant="outlined"
                      fullWidth
                      defaultValue={Company.noOfUser}
                      className={edit ? "textDisable" : ""}
                      error={errors.noOfUser ? true : false}
                      helperText={
                        !!errors.noOfUser && errors.noOfUser.message + ""
                      }
                      InputProps={{ readOnly: edit }}
                      {...register("noOfUser")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Address Line 1"
                      fullWidth
                      defaultValue={Company.addressLine1}
                      className={edit ? "textDisable" : ""}
                      error={errors.addressLine1 ? true : false}
                      helperText={
                        !!errors.addressLine1 &&
                        errors.addressLine1.message + ""
                      }
                      variant="outlined"
                      InputProps={{ readOnly: edit }}
                      {...register("addressLine1")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Email Id"
                      variant="outlined"
                      type="email"
                      fullWidth
                      defaultValue={Company.emailId}
                      className={edit ? "textDisable" : ""}
                      InputProps={{ readOnly: edit }}
                      error={errors.emailId ? true : false}
                      helperText={
                        !!errors.emailId && errors.emailId.message + ""
                      }
                      {...register("emailId")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="GST Number"
                      variant="outlined"
                      fullWidth
                      defaultValue={
                        Company.gstNumber
                          ? Company.gstNumber.trim()
                          : Company.gstNumber
                      }
                      className={edit ? "textDisable" : ""}
                      error={errors.gstNumber ? true : false}
                      helperText={
                        !!errors.gstNumber && errors.gstNumber.message + ""
                      }
                      InputProps={{ readOnly: edit }}
                      {...register("gstNumber")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <Autocomplete
                      options={States.countries}
                      getOptionLabel={(option) => option.country}
                      onChange={(event, value) => {
                        setSelectedCountry(value);
                        setValue("country", value && value.country);
                      }}
                      // defaultValue={Company}
                      defaultValue={selectedCountry}
                      disabled={edit ? true : false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          variant="outlined"
                          className={edit ? "textDisable" : ""}
                          error={errors.country ? true : false}
                          helperText={
                            errors.country === undefined
                              ? ""
                              : errors.country.message + ""
                          }
                          {...register("country")}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Address Line 2"
                      fullWidth
                      defaultValue={Company.addressLine2}
                      className={edit ? "textDisable" : ""}
                      error={errors.addressLine2 ? true : false}
                      helperText={
                        !!errors.addressLine2 &&
                        errors.addressLine2.message + ""
                      }
                      variant="outlined"
                      InputProps={{ readOnly: edit }}
                      {...register("addressLine2")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="City"
                      variant="outlined"
                      fullWidth
                      defaultValue={Company.city}
                      className={edit ? "textDisable" : ""}
                      error={errors.city ? true : false}
                      helperText={!!errors.city && errors.city.message + ""}
                      InputProps={{ readOnly: edit }}
                      {...register("city")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="PAN Card"
                      variant="outlined"
                      fullWidth
                      defaultValue={Company.pancard}
                      className={edit ? "textDisable" : ""}
                      error={errors.pancard ? true : false}
                      helperText={
                        !!errors.pancard && errors.pancard.message + ""
                      }
                      InputProps={{ readOnly: edit }}
                      {...register("pancard")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    {selectedCountry && (
                      <Autocomplete
                        options={selectedCountry.states}
                        onChange={(event, value) => {
                          setValue("state", value ? value : fetchState);
                          setSelectedState(value);
                        }}
                        defaultValue={selectedState || fetchState}
                        disabled={edit ? true : false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            variant="outlined"
                            className={edit ? "textDisable" : ""}
                            error={errors.state ? true : false}
                            helperText={
                              errors.state == undefined
                                ? ""
                                : errors.state.message + ""
                            }
                            {...register("state")}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Address Line 3"
                      variant="outlined"
                      fullWidth
                      defaultValue={Company.addressLine3}
                      className={edit ? "textDisable" : ""}
                      error={errors.addressLine3 ? true : false}
                      helperText={
                        !!errors.addressLine3 &&
                        errors.addressLine3.message + ""
                      }
                      InputProps={{ readOnly: edit }}
                      {...register("addressLine3")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Pin Code"
                      variant="outlined"
                      fullWidth
                      defaultValue={Company.pin}
                      className={edit ? "textDisable" : ""}
                      error={errors.pin ? true : false}
                      helperText={!!errors.pin && errors.pin.message + ""}
                      InputProps={{ readOnly: edit }}
                      {...register("pin")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      defaultValue={Company.phoneNo}
                      className={edit ? "textDisable" : ""}
                      error={errors.phoneNo ? true : false}
                      helperText={
                        !!errors.phoneNo && errors.phoneNo.message + ""
                      }
                      InputProps={{ readOnly: edit }}
                      {...register("phoneNo")}
                    />
                  </Grid>

                  {bankDetails.length !== 0 && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ padding: "0 10px" }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={bankDetails.map((option: any) => option)}
                        defaultValue={
                          bankDetails[
                            bankDetails.findIndex(
                              (item: any) =>
                                item.bankDetailId === Company.brokerBankDetailId
                            )
                          ]
                        }
                        getOptionLabel={(options: any) =>
                          options.bankName + " - " + options.accountNO
                        }
                        onChange={(options: any, newValue) => {
                          setValue(
                            "brokerBankDetailId",
                            newValue?.bankDetailId
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={edit ? "textDisable" : ""}
                            label="Select Broker bank"
                          />
                        )}
                        disabled={edit ? true : false}
                      />
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ padding: "0 10px" }}
                    textAlign={"left"}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={edit}
                          checked={isChecked}
                          onChange={handleLogoChange}
                        />
                      }
                      label="Show Logo"
                    />
                  </Grid>

                  {Company.companyTypeCode === "D" && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="h6" marginTop={2} marginBottom={2}>
                        {" "}
                        RM Details{" "}
                      </Typography>
                    </Grid>
                  )}

                  {Company.companyTypeCode === "D" && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ padding: "0 10px" }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        getOptionLabel={(options: any) => options.companyName}
                        options={employeeCompanyList.map(
                          (option: any) => option
                        )}
                        onChange={(options: any, newValue) => {
                          setEmpCompanyCode(newValue?.companyMasterCode);
                        }}
                        defaultValue={employeeCompanyList[0]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={edit ? "textDisable" : ""}
                            label="Select RM Company"
                          />
                        )}
                        disabled={edit ? true : false}
                      />
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    sx={{ padding: "0 10px" }}
                  >
                    {Company.companyTypeCode === "D" &&
                      Company &&
                      UserProfilesByCompanyMasterCode && (
                        <Autocomplete
                          id="Select Employee"
                          defaultValue={
                            UserProfilesByCompanyMasterCode &&
                            UserProfilesByCompanyMasterCode.data.userProfile.find(
                              (option: any) =>
                                option.employeeCode === Company.rmUserCode
                            )
                          }
                          options={
                            UserProfilesByCompanyMasterCode
                              ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                                  (options: any) => options
                                )
                              : []
                          }
                          getOptionLabel={(options: any) =>
                            options.employeeCode
                          }
                          onChange={(options: any, newValue) => {
                            setEmployee(newValue?.employeeCode);
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={edit ? "textDisable" : ""}
                              label="Select RM"
                            />
                          )}
                          disabled={edit ? true : false}
                        />
                      )}
                  </Grid>

                  {Company.companyTypeCode === "D" && (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h6" marginTop={2} marginBottom={2}>
                          {" "}
                          Bank Details{" "}
                        </Typography>
                      </Grid>
                      {/* BANK NAME */}
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{ padding: "0 10px" }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Bank Name"
                          variant="outlined"
                          fullWidth
                          defaultValue={
                            Company.bankName
                              ? Company.bankName.trim()
                              : Company.bankName
                          }
                          className={edit ? "textDisable" : ""}
                          error={errors.bankName ? true : false}
                          helperText={
                            !!errors.bankName && errors.bankName.message + ""
                          }
                          InputProps={{ readOnly: edit }}
                          {...register("bankName")}
                        />
                      </Grid>
                      {/* BRANCH NAME */}
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{ padding: "0 10px" }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Branch Name"
                          variant="outlined"
                          fullWidth
                          defaultValue={Company.branchName}
                          className={edit ? "textDisable" : ""}
                          error={errors.branchName ? true : false}
                          helperText={
                            !!errors.branchName &&
                            errors.branchName.message + ""
                          }
                          InputProps={{ readOnly: edit }}
                          {...register("branchName")}
                        />
                      </Grid>
                      {/* ACCOUNT NO */}
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{ padding: "0 10px" }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Account Number"
                          variant="outlined"
                          fullWidth
                          defaultValue={Company.accountNo}
                          className={edit ? "textDisable" : ""}
                          error={errors.accountNo ? true : false}
                          helperText={
                            !!errors.accountNo && errors.accountNo.message + ""
                          }
                          InputProps={{ readOnly: edit }}
                          {...register("accountNo")}
                        />
                      </Grid>
                      {/* IFSC NO. */}
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{ padding: "0 10px" }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="IFSC Code"
                          variant="outlined"
                          fullWidth
                          defaultValue={Company.ifscNo}
                          className={edit ? "textDisable" : ""}
                          error={errors.ifscNo ? true : false}
                          helperText={
                            !!errors.ifscNo && errors.ifscNo.message + ""
                          }
                          InputProps={{ readOnly: edit }}
                          {...register("ifscNo")}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h6" marginTop={2} marginBottom={2}>
                      {" "}
                      Upload File{" "}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      padding: "0 10px",
                      mt: 1,
                    }}
                  >
                    <label>Registration Certificate : </label>
                    {Company.registerOfCertificate ? (
                      <Button
                        variant="contained"
                        onClick={() =>
                          viewDocument(Company.registerOfCertificate)
                        }
                        sx={{ ml: 1 }}
                      >
                        {" "}
                        View{" "}
                      </Button>
                    ) : (
                      <Button variant="contained" disabled sx={{ ml: 1 }}>
                        View
                      </Button>
                    )}
                    {sessionStorage.getItem("Profile") === "B" ? (
                      <input
                        type="file"
                        onChange={handleRegisterChange}
                        disabled={edit}
                        style={{ paddingLeft: "10px" }}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      padding: "0 10px",
                      mt: 1,
                    }}
                  >
                    <label>GST Certificate : </label>
                    {Company.gstNumberDoc ? (
                      <Button
                        variant="contained"
                        onClick={() => viewDocument(Company.gstNumberDoc)}
                        sx={{ ml: 1 }}
                      >
                        {" "}
                        View{" "}
                      </Button>
                    ) : (
                      <Button variant="contained" disabled sx={{ ml: 1 }}>
                        View
                      </Button>
                    )}
                    {sessionStorage.getItem("Profile") === "B" ? (
                      <input
                        type="file"
                        onChange={handleGstChange}
                        disabled={edit}
                        style={{ paddingLeft: "10px" }}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      padding: "0 10px",
                      mt: 1,
                    }}
                  >
                    <label>Pancard : </label>
                    {Company.pancardDoc ? (
                      <Button
                        variant="contained"
                        onClick={() => viewDocument(Company.pancardDoc)}
                        sx={{ ml: 1 }}
                      >
                        {" "}
                        View{" "}
                      </Button>
                    ) : (
                      <Button variant="contained" disabled sx={{ ml: 1 }}>
                        View
                      </Button>
                    )}
                    {sessionStorage.getItem("Profile") === "B" ? (
                      <input
                        type="file"
                        onChange={handlePancardChange}
                        disabled={edit}
                        style={{ paddingLeft: "10px" }}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      padding: "0 10px",
                      mt: 1,
                    }}
                  >
                    <label>Cancel Cheque : </label>
                    {Company.cancelCheque ? (
                      <Button
                        variant="contained"
                        onClick={() => viewDocument(Company.cancelCheque)}
                        sx={{ ml: 1 }}
                      >
                        {" "}
                        View{" "}
                      </Button>
                    ) : (
                      <Button variant="contained" disabled sx={{ ml: 1 }}>
                        View
                      </Button>
                    )}
                    {sessionStorage.getItem("Profile") === "B" ? (
                      <input
                        type="file"
                        onChange={handleCancleChequeChange}
                        disabled={edit}
                        style={{ paddingLeft: "10px" }}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      padding: "0 10px",
                      mt: 1,
                    }}
                  >
                    <label>Company Logo : </label>
                    {Company.companyLogo ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          setDocumentFile(Company.companyLogo);
                          setOpen(true);
                        }}
                        sx={{ ml: 1 }}
                      >
                        {" "}
                        View{" "}
                      </Button>
                    ) : (
                      <Button variant="contained" disabled sx={{ ml: 1 }}>
                        View
                      </Button>
                    )}
                    {sessionStorage.getItem("Profile") === "B" ? (
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleCompanyLogoChange}
                        disabled={edit}
                        style={{ paddingLeft: "10px" }}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>

                <TextField
                  id="outlined-basic"
                  label="companyLogo"
                  variant="outlined"
                  defaultValue={Company.companyLogo}
                  InputProps={{ readOnly: edit }}
                  sx={{ display: "none" }}
                  {...register("companyLogo")}
                />

                {sessionStorage.getItem("Profile") === "B" ? (
                  <Typography variant="h5" marginTop={2} marginBottom={1}>
                    Product List
                  </Typography>
                ) : (
                  <></>
                )}
                {sessionStorage.getItem("Profile") === "B" ? (
                  <FormGroup>
                    <Grid container>
                      <Grid item xs={12}>
                        {ProductList.map((item: any) => (
                          <FormControlLabel
                            control={<Checkbox />}
                            onChange={() =>
                              handleChangeProduct(item.productTypeCode)
                            }
                            disabled={edit}
                            checked={purposeOption.includes(
                              item.productTypeCode + ""
                            )}
                            label={item.productName}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </FormGroup>
                ) : (
                  <></>
                )}
                {sessionStorage.getItem("Profile") === "B" ? (
                  !edit ? (
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent={"center"}
                      marginTop={3}
                    >
                      <Button type="submit" color="primary" variant="contained">
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={Active_Inactive_status}
                      >
                        {Company.isActive == true ? "In Active" : "Active"}
                      </Button>

                      <Button
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          setedit(true);
                          window.location.reload();
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  ) : (
                    <Button
                      sx={{ marginTop: 3 }}
                      variant="contained"
                      type="submit"
                      onClick={editDetails}
                    >
                      Edit Details
                    </Button>
                  )
                ) : (
                  <></>
                )}
              </Box>
            </form>
          </Container>

          {!(Company.companyTypeCode === "M") ? (
            <>
              <Divider variant="middle" sx={{ marginTop: "2%" }} />
              <Typography variant="h5" marginTop={2}>
                Payout
              </Typography>
              <Grid
                container
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                  marginTop: "3%",
                }}
              >
                <Grid item md={3}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={tabHandleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    {CompanyWiseProduct.map((it: any) =>
                      ProductList.filter(
                        (i: any) =>
                          parseInt(i.productTypeCode) ==
                          parseInt(it.destinationValue)
                      ).map((items: any, index: any) => (
                        <Tab
                          label={items.productName}
                          {...a11yProps(index)}
                          onClickCapture={() => {
                            // handleChangePayout(items.productName),
                            setproductlist(items.productName);
                            setBrorageProductTypeCode(items.productTypeCode);
                          }}
                        />
                      ))
                    )}
                  </Tabs>
                </Grid>

                <Grid item md={9}>
                  {productlist ? (
                    // <TabPanel value={value} index={value}>
                    <form onSubmit={handleSubmitForm2(onSubmit2)}>
                      <Grid
                        container
                        spacing={1.5}
                        sx={{ margin: "0 0 1.5rem 0", width: "100%" }}
                      >
                        {/* DISTRIBUTOR PAYOUT */}
                        <Grid
                          item
                          md={6}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          {/* {productlist === "Unlisted" ||
                            productlist === "UNLISTED" ? null : (
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="PERCENTAGE"
                                onChange={handleDistributorPayoutRadio}
                                value={DistPayoutRadio}
                              >
                                <Stack direction={"row"} spacing={3}>
                                  <FormControlLabel
                                    value="PERCENTAGE"
                                    control={<Radio />}
                                    label="Percentage %"
                                    disabled={
                                      sessionStorage.getItem("Profile") === "E"
                                    }
                                  />

                                  <FormControlLabel
                                    value="FIXED_VALUE"
                                    control={<Radio />}
                                    label="Value"
                                    disabled={
                                      sessionStorage.getItem("Profile") === "E"
                                    }
                                  />
                                </Stack>
                              </RadioGroup>
                            )} */}
                          <TextField
                            id="outlined-basic"
                            label={productlist + " Distributor Payout %"}
                            type="number"
                            value={custPayout}
                            variant="outlined"
                            onChangeCapture={(e: any) =>
                              setcustPayout(e.target.value)
                            }
                            {...registerForm2("custBrokerage")}
                            autoFocus={isDistributorFocused}
                            onClick={handleFocusDistributor}
                            //disable the field for Employee
                            disabled={
                              sessionStorage.getItem("Profile") === "E"
                                ? true
                                : false
                            }
                          />
                        </Grid>

                        {/* BROKER PAYOUT */}
                        <Grid
                          item
                          md={6}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          {/* RM & DISTRIBUTOR SHARING */}
                          <div style={{ display: "flex", gap: "0.5rem" }}>
                            <TextField
                              id="outlined-basic"
                              label={"RM Percentage %"}
                              type="number"
                              variant="outlined"
                              value={rmPercentage}
                              onChangeCapture={(e: any) => {
                                setRmPercentage(e.target.value);
                              }}
                              {...registerForm2("rmPercentage")}
                              autoFocus={isRMFocused}
                              onClick={handleFocusRM}
                              style={{ width: "50%" }}
                              disabled={
                                sessionStorage.getItem("Profile") === "E"
                                  ? true
                                  : false
                              }
                            />
                            <TextField
                              id="outlined-basic"
                              label={"Distributor Percentage %"}
                              type="number"
                              variant="outlined"
                              value={distPercentage}
                              onChangeCapture={(e: any) => {
                                setDistPercentage(e.target.value);
                              }}
                              {...registerForm2("distPercentage")}
                              autoFocus={isDistFocused}
                              onClick={handleFocusDist}
                              style={{ width: "50%" }}
                              disabled={
                                sessionStorage.getItem("Profile") === "E"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                      {sessionStorage.getItem("Profile") === "B" ? (
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      ) : (
                        ""
                      )}
                    </form>
                  ) : (
                    // </TabPanel>
                    <></>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {sessionStorage.getItem("Profile") === "E" ? (
            <>
              <Divider variant="middle" sx={{ marginTop: "2%" }} />
              <Typography variant="h6" marginTop={2} marginBottom={1}>
                Client List
              </Typography>
              <Grid>
                <TableContainer sx={{ mt: "2%" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr No.</TableCell>
                        <TableCell>Client Name</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Company Email Id</TableCell>
                        <TableCell align="right">Mobile No</TableCell>
                        <TableCell align="right">Client Code</TableCell>
                        {/* <TableCell align="right">Status</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? Clients.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : Clients
                      ).map((item: any, index: any) => (
                        <Link
                          key={item.clientId}
                          display={"table-row"}
                          sx={{
                            textDecoration: "none",
                            "&:hover": { backgroundColor: "lightgray" },
                          }}
                          href={"/client/" + item.clientId}
                        >
                          <TableCell align="left">{item.SrNo}</TableCell>
                          <TableCell component="th" scope="row">
                            {item.fullName}
                          </TableCell>
                          <TableCell>{item.companyName}</TableCell>
                          <TableCell>{item.emailId}</TableCell>
                          <TableCell align="right">{item.mobileNo}</TableCell>
                          <TableCell align="right">{item.clientCode}</TableCell>
                        </Link>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 30, 50]}
                  component="div"
                  count={Clients ? Clients.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>{" "}
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal
        open={open}
        onClose={handleCloseViewClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!!documentFile ? (
            documentFile.includes(".pdf") ? (
              <div
                className="rpv-core__viewer"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  flexDirection: "column",
                  height: "75%",
                  width: "75%",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "#eeeeee",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                  }}
                >
                  <ZoomOutButton />
                  <ZoomPopover />
                  <ZoomInButton />
                  <Tooltip title="Download">
                    <IconButton>
                      <CloudDownloadIcon
                        onClick={() => downloadDocument(documentFileId)}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                  }}
                >
                  <Viewer
                    fileUrl={documentFile}
                    plugins={[zoomPluginInstance]}
                  />
                </div>
              </div>
            ) : (
              <div
                className="rpv-core__viewer"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  flexDirection: "column",
                  height: "450px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "#eeeeee",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                  }}
                ></div>
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                  }}
                >
                  <img src={documentFile} alt="image" width={"100%"} />
                </div>
              </div>
            )
          ) : (
            <></>
          )}
        </Box>
      </Modal>
    </div>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
  display: "flex",
  justifyContent: "center",
};

export default withParam(CompanyProfileView);
