/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Container,
  Box,
  Stack,
  Button,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import FilterAif from "./Filters/FilterAif";
import Aif from "./Products/AIF/Aif";
import Equity from "./Products/Equity";
import FixedIncome from "./Products/FixedIncome";
import Insurance from "./Products/Insurance";
import MutualFund from "./Products/MutualFund";
import Pms from "./Products/Pms";
// import PrimaryIssue from "./Products/PrimaryIssue";
// import RealEstate from "./Products/RealEstate";
// import StartupEco from "./Products/StartupEco";
// import Training from "./Products/Training";
import Unlisted from "./Products/Unlisted";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
// import AdminUnlisted from "./Products/AdminUnlisted";
// import DistributorUnlisted from "./DistributorUnlisted";
import ResearchServices from "./Products/ResearchServices/ResearchServices";
import NCD from "./Products/NCD/NCD";
import Loan from "./Products/Loan/Loan";
import Investkul from "./Products/InvestKul/Investkul";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Products = () => {
  const [value, setValue] = React.useState<Number | String | any>(
    !!sessionStorage.getItem("ProductNumber")
      ? parseInt(sessionStorage.getItem("ProductNumber") + "")
      : 0
  );

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    sessionStorage.setItem("ProductNumber", newValue);
    setValue(newValue);
  };
  console.log("value", !!sessionStorage.getItem("ProductNumber"));
  console.log(
    "value",
    (sessionStorage.getItem("ProductNumber") + "").length !== 0
      ? parseInt(sessionStorage.getItem("ProductNumber") + "")
      : 0
  );

  return (
    <>
      <Box sx={{ width: "100%", minHeight: "calc(100vh - 150px)" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            sx={{ justifyContent: "space-between" }}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {sessionStorage.getItem("Profile") !== "D" && (
              <Tab label="Fixed Income" {...a11yProps(2)} />
            )}
            <Tab label="Mutual Fund" {...a11yProps(4)} />
            <Tab label="PMS" {...a11yProps(5)} />
            <Tab label="Aif" {...a11yProps(0)} />
            <Tab label="Research Services" {...a11yProps(11)} />
            <Tab label="Unlisted" {...a11yProps(10)} />
            <Tab label="Loan" {...a11yProps(13)} />
            <Tab label="Insurance" {...a11yProps(3)} />
            <Tab label="NCD" {...a11yProps(12)} />
            <Tab label="InvestKul" {...a11yProps(14)} />
            {/* <Tab label="Equity" {...a11yProps(1)} />
          <Tab label="Primary Issue" {...a11yProps(6)} />
          <Tab label="Real Estate" {...a11yProps(7)} />
          <Tab label="Startup Ecosystem" {...a11yProps(8)} />
          <Tab label="Training"{...a11yProps(9)} /> */}
          </Tabs>
        </Box>
        {sessionStorage.getItem("Profile") !== "D" ? (
          <>
            <TabPanel value={value} index={0}>
              <FixedIncome />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MutualFund />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Pms />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Aif />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ResearchServices />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Unlisted />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Loan />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <Insurance />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <NCD />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <Investkul />
            </TabPanel>
          </>
        ) : (
          <>
            <TabPanel value={value} index={0}>
              <MutualFund />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Pms />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Aif />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ResearchServices />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Unlisted />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Loan />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Insurance />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <NCD />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <Investkul />
            </TabPanel>
          </>
        )}
      </Box>
    </>
  );
};

export default Products;
