import React from 'react'
import { Box, Button, Card, CardActions, CardContent, CardMedia, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import { Link } from 'react-router-dom';

const TotalClientsForEmployee = (props:any) => {
  const { title } = props
	const { Count } = props
  const { BarData } = props
  const {monthoscunt}=props
  const {quarterlyCount}=props
  const {TotalYearCount}=props
  const {prevQuarterValue} = props
  const {prevQuarterStartDate}=props
  const {prevQuarterEndDate}=props
  const {YtdTotalClient}=props
  const {yearly}=props
  const {quarterly}=props
  const {monthly}=props
  const {currentDate}=props
  
  return (
	<Paper elevation={6} sx={{pb:0}}>
		 <Card sx={{pb:0}}>
      <Table aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell sx={{background:"#d0cece"}} colSpan={5} align="center"> {title}({Count})</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{fontSize:"12px",fontWeight:"700",textAlign:"center"}}>Month<br /> <b style={{fontSize:"10px"}}>({monthly})</b></TableCell>
        <TableCell style={{fontSize:"12px",fontWeight:"700",padding:"0px"}} align="center">QTR<br /><b style={{fontSize:"10px"}} >({quarterly})</b></TableCell>
        <TableCell style={{fontSize:"12px",fontWeight:"700",padding:"0px"}} align="center">PRV QTR<br /><b style={{fontSize:"10px"}}>({prevQuarterStartDate}-{prevQuarterEndDate})</b></TableCell>
        <TableCell style={{fontSize:"12px",fontWeight:"700"}} align="center">YTD<br /><b style={{fontSize:"10px"}}>({currentDate})</b></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell style={{fontSize:"10px",textAlign:"center"}} component="th" scope="row"><b>{monthoscunt}</b></TableCell>
        <TableCell style={{fontSize:"10px"}} align="center"><b>{quarterlyCount}</b></TableCell>
        <TableCell style={{fontSize:"10px"}} align="center"><b>{prevQuarterValue}</b></TableCell>
        <TableCell  style={{fontSize:"10px"}} align="center"><b>{YtdTotalClient }</b></TableCell>
      </TableRow>
    </TableBody>
  </Table>
    </Card>
	</Paper>
  )
}

export default TotalClientsForEmployee