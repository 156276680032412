import React from 'react'
import { Box, Card, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import { numDifferentiation } from '../../utils/Helper'


const TransactionValueForEmployee = (props:any) => {
  const { title } = props
  const { yearlyCount } = props
  const { quarterlyCount } = props
  const { monthlyCount } = props
  const {prevQuarterValue}=props
  const {prevQuarterStartDate}=props
  const {prevQuarterEndDate}=props
  const {yearly}=props
  const {quarterly}=props
  const {monthly}=props
  const {currentDate}=props

  return (
	<Paper elevation={6} sx={{pb:0}}>
		 <Card sx={{pb:0}}>
      <Table aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell sx={{background:"#92d050"}} colSpan={4} align="center">Transaction Value(Rs)</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{fontSize:"12px",fontWeight:"700",textAlign:"center"}} >Month <br /> <b style={{fontSize:"10px"}}> ({monthly}) </b></TableCell>
        <TableCell style={{fontSize:"12px",fontWeight:"700",padding:"0px"}} align="center">QTR <br /> <b style={{fontSize:"10px"}}>({quarterly})</b> </TableCell>
        <TableCell style={{fontSize:"12px",fontWeight:"700",padding:"0px"}} align="center"> Prv QTR  <br /><b style={{fontSize:"10px"}}>({prevQuarterStartDate})-({prevQuarterEndDate})</b></TableCell>
        <TableCell style={{fontSize:"12px",fontWeight:"700"}} align="center">YTD <br /> <b style={{fontSize:"10px"}}>({currentDate})</b> </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell style={{fontSize:"10px",textAlign:"center"}} component="th" scope="row"><b>{numDifferentiation(monthlyCount)}</b></TableCell>
        <TableCell style={{fontSize:"10px"}} align="center"><b>{numDifferentiation(quarterlyCount)}</b></TableCell>
        <TableCell style={{fontSize:"10px"}} align="center"><b>{numDifferentiation(prevQuarterValue)}</b></TableCell>
        <TableCell style={{fontSize:"10px"}} align="center"><b>{numDifferentiation(yearlyCount)}</b></TableCell>
      </TableRow>
    </TableBody>
  </Table>
    </Card>
	</Paper>
  )
}

export default TransactionValueForEmployee